import React from 'react';
import { Switch, Route } from 'react-router-dom';

import * as routes from 'src/constants/routes';

import AddSupporter from './Add';
import EditSupporter from './Edit';
import ListSupport from './List';

const Router: React.FC = () => (
  <Switch>
    <Route exact path={routes.FRONTLINE_SUPPORTER} component={ListSupport} />
    <Route path={routes.FRONTLINE_SUPPORTER_ADD} component={AddSupporter} />
    <Route path={routes.FRONTLINE_SUPPORTER_EDIT} component={EditSupporter} />
  </Switch>
);

export default Router;
