import axios from 'src/utils/axios';
import { AxiosResponse } from 'axios';

export interface IGlobalCount {
  _id: string;
  recoveredTotal: number;
  confirmedTotal: number;
  deathTotal: number;
  confirmedToday: number;
  recoveredToday: number;
  deathToday: number;
  createdAt: string;
  updatedAt: string;
}

export interface INepalCount {
  _id: string;
  testedTotal: number;
  recoveredTotal: number;
  confirmedTotal: number;
  deathTotal: number;
  testedToday: number;
  confirmedToday: number;
  recoveredToday: number;
  deathToday: number;
  createdAt: string;
  updatedAt: string;
}

export async function fetchGlobalLiveCountAPI() {
  try {
    const URL = `counts/global/latest`;
    const response: AxiosResponse<IGlobalCount> = await axios.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function fetchNepalLiveCountAPI() {
  try {
    const URL = `counts/nepal/latest`;
    const response: AxiosResponse<INepalCount> = await axios.get(URL);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export interface IUpdateGlobalCountPayload {
  id: string;
  confirmedTotal: number;
  recoveredTotal: number;
  deathTotal: number;
}

export async function updateGlobalCountAPI(payload: IUpdateGlobalCountPayload) {
  try {
    const { id, ...rest } = payload;
    const URL = `counts/global/${id}`;
    const response: AxiosResponse = await axios.put(URL, rest);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export interface IUpdateNepalCountPayload {
  id: string;
  testedTotal: number;
  confirmedTotal: number;
  recoveredTotal: number;
  deathTotal: number;
}

export async function updateNepalCountAPI(payload: IUpdateNepalCountPayload) {
  try {
    const { id, ...rest } = payload;
    const URL = `counts/nepal/${id}`;
    const response: AxiosResponse = await axios.put(URL, rest);
    return response.data;
  } catch (error) {
    throw error;
  }
}
