import React, { FC } from 'react';

interface IFormCheckbox {
  label: string;
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  checked?: boolean;
}

const FormCheckbox: FC<IFormCheckbox> = ({
  label,
  className,
  value,
  handleChange,
  checked
}) => {
  return (
    <>
      <input
        id={value}
        name={value}
        className={className}
        type="checkbox"
        value={value}
        onChange={handleChange}
        checked={checked ? checked : false}
      />
      <label className="form-check-label">{label}</label>
    </>
  );
};

export default FormCheckbox;
