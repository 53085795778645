import React from 'react';
import { Switch, Route } from 'react-router-dom';
import * as routes from 'src/constants/routes';
import CovidCasesMain from './Main';
import UpdateGlobalCases from './Update/GlobalCases';
import UpdateNepalCases from './Update/NepalCases';

const NotFound = () => <p>404 - Not Found</p>;

const Router = () => (
  <Switch>
    <Route exact path={routes.COVID_CASES} component={CovidCasesMain} />
    <Route
      path={routes.COVID_CASES_UPDATE_GLOBAL}
      component={UpdateGlobalCases}
    />
    <Route
      path={routes.COVID_CASES_UPDATE_NEPAL}
      component={UpdateNepalCases}
    />
    <Route component={NotFound} />
  </Switch>
);

export default Router;
