import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import * as routes from 'src/constants/routes';
import { IRequester } from 'src/services/api/frontLine';

interface IRequesterTableRowProps {
  requester: IRequester;
  index: number;
  toggleDeleteModal: (id?: string) => void;
}
const RequesterTableRow: React.FC<IRequesterTableRowProps> = props => {
  const {
    index,
    requester: { _id, name, contact, organization }
  } = props;
  return (
    <tr>
      <td>{index + 1}</td>
      <td>{name}</td>
      <td>{contact.email}</td>
      <td>{organization}</td>
      <td>{contact.phone}</td>
      <td>
        <Link to={`${routes.FRONTLINE_REQUESTER}/edit/${_id}`}>
          <Button>Edit</Button>
        </Link>
        <Button
          className={'ml-1'}
          variant="danger"
          onClick={() => {
            props.toggleDeleteModal(_id);
          }}
        >
          Delete
        </Button>
      </td>
    </tr>
  );
};

export default RequesterTableRow;
