import React from 'react';

import Router from './Router';

const Support: React.FC = () => {
  return (
    <div className="container-fluid" style={{ marginTop: '20px' }}>
      <Router />
    </div>
  );
};

export default Support;
