import axios from '../../utils/axios';

import { DISTRICT_API } from '../../constants/apis';
import { AxiosResponse, CancelToken } from 'axios';
import { IPayload, IDistrict } from '../../constants/common';

export async function getDistritByProvince(
  province: string,
  cancelToken: CancelToken
): Promise<IDistrict[]> {
  try {
    const response: AxiosResponse<IPayload<IDistrict[]>> = await axios.get(
      `${DISTRICT_API}?province=${province}`,
      {
        cancelToken
      }
    );
    return response.data.docs;
  } catch (e) {
    console.trace(e);
    throw e;
  }
}
