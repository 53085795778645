import axios from 'axios';
import * as Config from '../config';
import qs from 'qs';

const axiosInstance = axios.create({
  baseURL: Config.BASE_URL,
  timeout: 0,
  paramsSerializer: function(params) {
    return qs.stringify(params, { encode: false });
  }
});

axiosInstance.interceptors.request.use(config => {
  if (
    [
      'delete',
      'DELETE',
      'post',
      'POST',
      'put',
      'PUT',
      'patch',
      'PATCH'
    ].includes(config.method || '')
  ) {
    config.headers = {
      ...config.headers,
      Authorization:
        'Basic YXBpYWRtaW46MzdkMjI0YjItYTBkMC00Nzg2LWEzMzEtNzA4Y2VlYTRhZTkz'
    };
  }
  return config;
});

export default axiosInstance;
