import React, { FC } from 'react';
import { withFormik, InjectedFormikProps } from 'formik';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as noty from 'src/utils/noty';
import RequesterFormElements, {
  IRequesterFormElementsValues
} from '../common/RequesterFormElements';
import * as routes from 'src/constants/routes';
import {
  getAllSupportItems,
  getOtherSupportItems,
  getSupportersIds
} from 'src/helpers/support';
import {
  IRequester,
  updateRequestAPI,
  ISupporter
} from 'src/services/api/frontLine';
import { supportRequestorsSchema } from 'src/validations/requester';
import { YesNoOption } from 'src/constants/options';
interface IEditRequesterForm {
  requester: IRequester;
}
const EditRequesterForm: FC<InjectedFormikProps<
  IEditRequesterForm,
  IRequesterFormElementsValues
>> = props => {
  const { handleSubmit } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <RequesterFormElements {...props} />
      <Row>
        <Col sm="12" className="text-right mt-3">
          <Form.Group controlId="terminalAction">
            <Link to={routes.FRONTLINE_REQUESTER}>
              <Button variant="link" className="mr-4">
                Cancel
              </Button>
            </Link>

            <Button variant="primary" type="submit" className="px-4">
              Update Requester
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};
const WrappedWithFormik = withFormik<
  IEditRequesterForm,
  IRequesterFormElementsValues
>({
  enableReinitialize: true,
  mapPropsToValues(props) {
    const { requester } = props;
    const otherSupportItems = getOtherSupportItems(
      requester.requestedItems
    ).join(', ');

    return {
      name: requester.name || '',
      email: requester.contact.email || '',
      phone: requester.contact.phone || '',
      organization: requester.organization || '',
      fulfilledBy: requester.fulfilledBy
        ? requester.fulfilledBy.map((supporter: ISupporter) => ({
            label: supporter.name,
            value: supporter._id
          }))
        : null,
      isFulfilled: requester.isFulfilled
        ? YesNoOption.filter(
            option => option.value === requester.isFulfilled
          )[0]
        : null,
      isVerified: requester.isVerified
        ? YesNoOption.filter(option => option.value === requester.isVerified)[0]
        : null,
      location: requester.location || '',
      otherSupportItems,
      requestedItems: requester.requestedItems || [],
      additionalSupport: requester.others || ''
    };
  },
  validationSchema: supportRequestorsSchema,
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      const {
        name,
        organization,
        location,
        fulfilledBy,
        email,
        phone,
        otherSupportItems,
        requestedItems,
        isVerified,
        isFulfilled,
        additionalSupport,
        ...rest
      } = values;
      const allRequestedItems = getAllSupportItems(
        otherSupportItems,
        requestedItems
      );
      const payload = {
        id: props.requester._id,
        name,
        organization,
        location,
        fulfilledBy: fulfilledBy
          ? (getSupportersIds(fulfilledBy) as Array<string>)
          : [],
        contact: {
          email: email,
          phone: phone
        },
        isVerified: isVerified ? (isVerified.value as boolean) : false,
        isFulfilled: isFulfilled ? (isFulfilled.value as boolean) : false,
        requestedItems: allRequestedItems,
        others: additionalSupport
      };
      console.log(payload, values, rest);
      await updateRequestAPI(payload);
      noty.success('Requester details has been added successfully');
      window.location.assign(routes.FRONTLINE_REQUESTER);
    } catch (e) {
      noty.error('Failed to add requester details');
      console.log(e);
    }
  }
});
export default WrappedWithFormik(EditRequesterForm);
