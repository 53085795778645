import React, { useState, useEffect } from 'react';
import {
  IGlobalCount,
  fetchGlobalLiveCountAPI
} from 'src/services/api/covidCases';
import UpdateGlobalCasesForm from './UpdateGlobalCasesForm';

const UpdateGlobalCases: React.FC = () => {
  const [formData, setFormData] = useState<IGlobalCount>({} as IGlobalCount);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetchGlobalData();
  }, []);

  const fetchGlobalData = async () => {
    try {
      const response = await fetchGlobalLiveCountAPI();
      setFormData(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoaded(true);
    }
  };

  return (
    <div className="container">
      <h4 className="text-center" style={{ margin: '20px' }}>
        Update Global Cases Count
      </h4>
      {isLoaded ? (
        <UpdateGlobalCasesForm globalCounts={formData} />
      ) : (
        <p className="text-center">Loading...</p>
      )}
    </div>
  );
};

export default UpdateGlobalCases;
