import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import * as routes from 'src/constants/routes';
import CovidCases from 'src/routes/dashboard/CovidCases';
import Hospital from 'src/routes/dashboard/Hospital';
import FrontLine from 'src/routes/dashboard/FrontLine';
import News from 'src/routes/dashboard/News';

const NotFound = () => <p>404-Not Found</p>;

const Router: React.FC<{}> = () => (
  <Switch>
    <Route
      exact
      path={routes.DASHBOARD}
      render={() => <Redirect to={routes.COVID_CASES} />}
    />
    <Route path={routes.COVID_CASES} component={CovidCases} />
    <Route path={routes.HOSPITAL} component={Hospital} />
    <Route path={routes.FRONTLINE} component={FrontLine} />
    <Route path={routes.NEWS} component={News} />
    <Route component={NotFound} />
  </Switch>
);

export default Router;
