import React from 'react';
import { Switch, Route } from 'react-router-dom';

import * as routes from '../../../constants/routes';
import Requester from './Requester';
import Supporter from './Supporter';

const Router: React.FC = () => (
  <Switch>
    <Route exact path={routes.FRONTLINE} component={Requester} />
    <Route path={routes.FRONTLINE_REQUESTER} component={Requester} />
    <Route path={routes.FRONTLINE_SUPPORTER} component={Supporter} />
  </Switch>
);

export default Router;
