import React, { FC, useState, useEffect, useRef } from 'react';
import { ValueType } from 'react-select';
import { debounce } from 'lodash';

import HospitalTable from './Table';
import { IHospital, fetchHospitalCapacityAPI } from 'src/services/api/hospital';
import HospitalTableFilter from './Table/HospitalTableFilter';
import { IOptions, ProvinceOptions } from 'src/constants/options';
import Pagination from 'src/components/Pagination';
import { IPagination } from 'src/components/Pagination/Pagination';
export interface IHospitalContext {
  isLoaded: boolean;
  hospitalList: Array<IHospital>;
}

export interface IHospitalCapacityFilters {
  province: IOptions;
  district: IOptions;
  hospitalName: string;
}

const initialHospitalCapacityFiltersState = {
  province: ProvinceOptions[2],
  district: { label: 'Kathmandu', value: 'Kathmandu' },
  hospitalName: ''
};
const initialPaginationState = {
  page: 1,
  size: 10,
  pages: 0,
  total: 0
};

export const HospitalContext = React.createContext({} as IHospitalContext);

const HospitalMain: FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hospitalList, setHospitalList] = useState<Array<IHospital>>([]);
  const [pagination, setPagination] = useState<IPagination>(
    initialPaginationState
  );
  const [filters, setFilters] = useState<IHospitalCapacityFilters>(
    initialHospitalCapacityFiltersState
  );

  useEffect(() => {
    const { page, size } = pagination;
    fetchHospitalCapacityDataBySearch(filters, page, size);
  }, [filters, pagination.page]);

  const fetchHospitalCapacityData = async (
    filter: IHospitalCapacityFilters,
    page: number,
    size: number
  ) => {
    setIsLoaded(false);
    try {
      const { province, district, hospitalName } = filter;
      let payload = {
        province: province ? (province.value as string) : '',
        district: district ? (district.value as string) : '',
        name: hospitalName,
        page,
        size
      };
      const response = await fetchHospitalCapacityAPI(payload);
      const { docs, ...rest } = response;
      setHospitalList(docs);
      setPagination(rest);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoaded(true);
    }
  };
  const fetchHospitalCapacityDataBySearch = useRef(
    debounce(fetchHospitalCapacityData, 400)
  ).current;

  const handlePageChange = (pageNumber: number) => {
    setPagination(prevPaginationState => ({
      ...prevPaginationState,
      page: pageNumber
    }));
  };
  const handleProvinceFilterChange = (value: ValueType<IOptions>) => {
    const selectedField = value as IOptions;
    setFilters({
      ...filters,
      province: selectedField,
      district: { label: 'All Districts', value: '' }
    });
  };
  const handleDistrictFilterChange = (value: ValueType<IOptions>) => {
    const selectedField = value as IOptions;
    setFilters({ ...filters, district: selectedField });
  };
  const handleSearchKeywordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let newFilter = { ...filters, hospitalName: event.currentTarget.value };
    if (filters.district.value || filters.province.value) {
      newFilter = {
        ...newFilter,
        province: { label: 'All Provinces', value: '' },
        district: { label: 'All Districts', value: '' }
      };
    }
    setFilters(newFilter);
  };
  return (
    <div className="container-fluid" style={{ marginTop: '20px' }}>
      <h3>Hospital Capacity</h3>
      <HospitalContext.Provider value={{ isLoaded, hospitalList }}>
        <HospitalTableFilter
          handleProvinceFilterChange={handleProvinceFilterChange}
          handleDistrictFilterChange={handleDistrictFilterChange}
          filter={filters}
          handleSearchKeywordChange={handleSearchKeywordChange}
        />
        <HospitalTable page={pagination.page} size={pagination.size} />
        <Pagination {...pagination} handlePageChange={handlePageChange} />
      </HospitalContext.Provider>
    </div>
  );
};

export default HospitalMain;
