import { SupportItemsList, IOptions } from 'src/constants/options';

// returns combined array of checked support items and "other" field's support items

export const getAllSupportItems = (
  otherSupportItems: string,
  requestedItems: Array<string>
) => {
  if (otherSupportItems.trim().length > 0) {
    const arrayOfOtherSupportItems = otherSupportItems
      .split(',')
      .map(item => item.trim());

    let otherItems: Array<string> = [];

    arrayOfOtherSupportItems.map(otherItem => {
      if (!requestedItems.includes(otherItem)) {
        otherItems.push(otherItem);
      }
    });
    const totalSupportItems = [...requestedItems, ...otherItems];
    return totalSupportItems;
  } else {
    return requestedItems;
  }
};

export const getOtherSupportItems = (requestedItems: Array<string>) => {
  const supportItemValues = SupportItemsList.map(
    supportItem => supportItem.value
  );

  let otherItems: Array<string> = [];

  requestedItems.map(requestedItem => {
    if (!supportItemValues.includes(requestedItem)) {
      otherItems.push(requestedItem);
    }
  });

  return otherItems;
};

export const getSupportersIds = (supporters: Array<IOptions>) => {
  const supportersIds = supporters.map(supporter => supporter.value);
  return supportersIds;
};
