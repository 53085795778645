import React from 'react';
import { withFormik, InjectedFormikProps } from 'formik';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Link, RouteComponentProps } from 'react-router-dom';

import * as noty from 'src/utils/noty';
import * as routes from 'src/constants/routes';
import SupporterFromElement, {
  ISupporterFormElementsValues
} from '../common/SupporterFormElement';
import { getAllSupportItems } from 'src/helpers/support';
import { createSupporter } from 'src/services/api/frontLine';
import { supporterSchema } from 'src/validations/supporter';

interface IAddSupporterProps extends RouteComponentProps {}

const AddSupporter: React.FC<InjectedFormikProps<
  IAddSupporterProps,
  ISupporterFormElementsValues
>> = props => {
  const { handleSubmit } = props;
  return (
    <div className="container">
      <h4 className="text-center" style={{ margin: '20px' }}>
        Add Supporter
      </h4>
      <Form onSubmit={handleSubmit}>
        <SupporterFromElement {...props} />
        <Row>
          <Col sm="12" className="text-right mt-3">
            <Form.Group controlId="terminalAction">
              <Link to={routes.FRONTLINE_SUPPORTER}>
                <Button variant="link" className="mr-4">
                  Cancel
                </Button>
              </Link>

              <Button variant="primary" type="submit" className="px-4">
                Add Supporter
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
const WrappedWithFormik = withFormik<
  IAddSupporterProps,
  ISupporterFormElementsValues
>({
  enableReinitialize: true,
  mapPropsToValues(props) {
    return {
      name: '',
      email: '',
      phone: '',
      organization: '',
      isVerified: null,
      location: '',
      others: '',
      providedItems: [],
      otherSupportItems: '',
      fulfillByDate: ''
    };
  },
  validationSchema: supporterSchema,
  handleSubmit: async (values, { props, setSubmitting }) => {
    const {
      name,
      email,
      phone,
      organization,
      isVerified,
      location,
      others,
      providedItems,
      otherSupportItems,
      fulfillByDate
    } = values;
    const allSupportedItems = getAllSupportItems(
      otherSupportItems,
      providedItems
    );
    const payload = {
      name,
      organization,
      location,
      fulfillByDate,
      contact: {
        email: email,
        phone: phone
      },
      others,
      providedItems: allSupportedItems,
      isVerified: isVerified ? (isVerified.value as boolean) : false
    };

    try {
      await createSupporter(payload);
      props.history.push(routes.FRONTLINE_SUPPORTER);
      noty.success('Supporter details has been added successfully');
    } catch (e) {
      noty.error('Failed to add supporter details');
      console.log(e);
    }
  }
});
export default WrappedWithFormik(AddSupporter);
