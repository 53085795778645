import React from 'react';

import Router from './Router';

const Requester: React.FC = () => {
  return (
    <div className="container-fluid">
      <Router />
    </div>
  );
};
export default Requester;
