import React from 'react';
import { withFormik, InjectedFormikProps } from 'formik';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Link, RouteComponentProps } from 'react-router-dom';

import * as noty from 'src/utils/noty';
import * as routes from 'src/constants/routes';
import SupporterFromElement, {
  ISupporterFormElementsValues
} from '../common/SupporterFormElement';
import { getAllSupportItems, getOtherSupportItems } from 'src/helpers/support';
import { updateSupporterAPI, ISupporter } from 'src/services/api/frontLine';
import { supporterSchema } from 'src/validations/supporter';

interface IEditSupporterFormProps extends RouteComponentProps {
  supporter: ISupporter;
}

const EditSupporterForm: React.FC<InjectedFormikProps<
  IEditSupporterFormProps,
  ISupporterFormElementsValues
>> = props => {
  const { handleSubmit } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <SupporterFromElement {...props} />
      <Row>
        <Col sm="12" className="text-right mt-3">
          <Form.Group controlId="terminalAction">
            <Link to={routes.FRONTLINE_SUPPORTER}>
              <Button variant="link" className="mr-4">
                Cancel
              </Button>
            </Link>

            <Button variant="primary" type="submit" className="px-4">
              Update Supporter
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};
const WrappedWithFormik = withFormik<
  IEditSupporterFormProps,
  ISupporterFormElementsValues
>({
  enableReinitialize: true,
  mapPropsToValues(props) {
    const otherSupportItems = getOtherSupportItems(
      props.supporter.providedItems
    ).join(', ');
    const { supporter } = props;
    return {
      _id: supporter._id,
      name: supporter.name || '',
      email: supporter.contact.email || '',
      phone: supporter.contact.phone || '',
      organization: supporter.organization || '',
      isVerified: {
        label: supporter.isVerified ? 'Yes' : 'No',
        value: supporter.isVerified || false
      },
      location: supporter.location || '',
      others: supporter.others || '',
      providedItems: supporter.providedItems || [],
      otherSupportItems,
      fulfillByDate: supporter.fulfillByDate || ''
    };
  },
  validationSchema: supporterSchema,
  handleSubmit: async (values, { props, setSubmitting }) => {
    const {
      _id,
      name,
      email,
      phone,
      organization,
      isVerified,
      location,
      others,
      providedItems,
      otherSupportItems,
      fulfillByDate
    } = values;
    const allSupportedItems = getAllSupportItems(
      otherSupportItems,
      providedItems
    );
    const payload = {
      _id: _id as string,
      name,
      organization,
      location,
      fulfillByDate,
      contact: {
        email: email,
        phone: phone
      },
      others,
      providedItems: allSupportedItems,
      isVerified: isVerified ? (isVerified.value as boolean) : false
    };

    try {
      await updateSupporterAPI(payload);
      props.history.push(routes.FRONTLINE_SUPPORTER);
      noty.success('Supporter details has been updated successfully');
    } catch (e) {
      noty.error('Failed to add supporter details');
      console.log(e);
    }
  }
});
export default WrappedWithFormik(EditSupporterForm);
