import React from 'react';
import LiveDataCards from 'src/components/LiveDataCards';
import { INepalCount } from 'src/services/api/covidCases';
import * as routes from 'src/constants/routes';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

interface INepalCasesProps {
  liveData: INepalCount;
}

const NepalCases: React.FC<INepalCasesProps> = props => {
  const { liveData } = props;

  return (
    <div style={{ marginTop: '20px' }}>
      <LiveDataCards
        totalTested={liveData.testedTotal}
        totalConfirmed={liveData.confirmedTotal}
        totalRecovered={liveData.recoveredTotal}
        totalDeaths={liveData.deathTotal}
      />
      <div className={'text-right'} style={{ marginTop: '20px' }}>
        <Link to={routes.COVID_CASES_UPDATE_NEPAL}>
          <Button variant="info">Update Nepal Data</Button>
        </Link>
      </div>
    </div>
  );
};

export default NepalCases;
