import React from 'react';
import { InjectedFormikProps, withFormik } from 'formik';
import NewsFormElements, {
  INewsFormValues
} from '../common/Form/NewsFormElements';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as routes from 'src/constants/routes';
import * as noty from 'src/utils/noty';
import { newsSchema } from 'src/validations/news';
import { createNewsAPI, INews } from 'src/services/api/news';
import { NewsTypeOptions } from 'src/constants/options';

const AddNews: React.FC<InjectedFormikProps<{}, INewsFormValues>> = props => {
  const { handleSubmit, isSubmitting } = props;

  return (
    <div className="container">
      <h4 className="text-center" style={{ margin: '20px' }}>
        Add News
      </h4>
      <Form onSubmit={handleSubmit}>
        <NewsFormElements {...props} />
        <Row>
          <Col sm="12" className="text-right mt-3">
            <Form.Group>
              <Link to={routes.NEWS}>
                <Button variant="link" className="mr44">
                  Cancel
                </Button>
              </Link>

              <Button
                variant="primary"
                type="submit"
                className="px-4"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Adding news...' : 'Add News'}
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const WrappedWithFormik = withFormik<{}, INewsFormValues>({
  enableReinitialize: true,
  mapPropsToValues(props) {
    return {
      title: '',
      description: '',
      url: '',
      imageUrl: '',
      source: '',
      type: NewsTypeOptions[0],
      uploadedAt: ''
    };
  },
  validationSchema: newsSchema,
  handleSubmit: async (values, { props, setSubmitting }) => {
    const payload: INews = {
      type: values.type.value.toString(),
      title: values.title,
      description: values.description,
      imageUrl: values.imageUrl
        ? values.imageUrl
        : 'https://youngfreeflorida.com/wp-content/uploads/2019/06/Marketplace-Lending-News.jpg',
      source: values.source,
      uploadedAt: values.uploadedAt,
      url: values.url
    };

    try {
      setSubmitting(true);
      await createNewsAPI(payload);
      noty.success('News added successfully');
      window.location.assign(routes.NEWS);
    } catch (error) {
      setSubmitting(false);
      noty.error('Failed to add news');
    }
  }
});

export default WrappedWithFormik(AddNews);
