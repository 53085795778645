import React from 'react';
import { CardDeck, Card } from 'react-bootstrap';
import { setCommas } from 'src/utils/stringManipuation';

interface ILiveDataCardsProps {
  totalTested?: number;
  totalConfirmed: number;
  totalRecovered: number;
  totalDeaths: number;
}

const LiveDataCards: React.FC<ILiveDataCardsProps> = props => {
  const { totalTested, totalConfirmed, totalRecovered, totalDeaths } = props;

  return (
    <CardDeck>
      <Card>
        <Card.Body>
          <h2>{totalTested ? setCommas(totalTested) : 'NA'}</h2>
          <span className="text-muted">Total Tested</span>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h2>{totalConfirmed && setCommas(totalConfirmed)}</h2>
          <span className="text-muted">Total Confirmed</span>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h2>{totalRecovered && setCommas(totalRecovered)}</h2>
          <span className="text-muted">Total Recovered</span>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h2>{totalDeaths && setCommas(totalDeaths)}</h2>
          <span className="text-muted">Total Deaths</span>
        </Card.Body>
      </Card>
    </CardDeck>
  );
};

export default LiveDataCards;
