import React, { FC, useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { ValueType } from 'react-select';

import { fetchRequesterAPI, IRequester } from 'src/services/api/frontLine';
import Pagination from 'src/components/Pagination';
import { IPagination } from 'src/components/Pagination/Pagination';
import RequesterTable from './Table';
import { IOptions } from 'src/constants/options';
import RequesterTableFilter from './Table/RequesterTableFilter';

const initialPaginationState = {
  page: 1,
  size: 10,
  pages: 0,
  total: 0
};
export interface IRequesterFilters {
  name: string;
  items: IOptions;
}
const initialRequesterFiltersState = {
  items: { label: 'All Items', value: '' },
  name: ''
};
const RequestList: FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [requesterList, setRequsterList] = useState<IRequester[]>([]);
  const [pagination, setPagination] = useState<IPagination>(
    initialPaginationState
  );
  const [filters, setFilters] = useState<IRequesterFilters>(
    initialRequesterFiltersState
  );

  useEffect(() => {
    const { page, size } = pagination;

    getSupporterList(filters, page, size);
  }, [filters, pagination.page]);

  const fetchRequesterList = async (
    filter: IRequesterFilters,
    page: number,
    size: number
  ) => {
    const { name, items } = filter;
    try {
      const payload = {
        page,
        size,
        name,
        items: items ? (items.value as string) : ''
      };

      setIsLoaded(false);
      const response = await fetchRequesterAPI(payload);
      const { docs, ...rest } = response;
      setRequsterList(docs);
      setPagination(rest);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoaded(true);
    }
  };
  const getSupporterList = useRef(debounce(fetchRequesterList, 400)).current;

  const handlePageChange = (pageNumber: number) => {
    setPagination(prevPaginationState => ({
      ...prevPaginationState,
      page: pageNumber
    }));
  };
  const handleSupportItemsChange = (value: ValueType<IOptions>) => {
    const selectedField = value as IOptions;
    setFilters({ ...filters, items: selectedField });
  };
  const handleSearchKeywordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilters({
      name: event.currentTarget.value,
      items: { label: 'All Items', value: '' }
    });
  };
  return (
    <>
      <h3> Requester List </h3>
      <RequesterTableFilter
        filter={filters}
        handleSearchKeywordChange={handleSearchKeywordChange}
        handleSupportItemsChange={handleSupportItemsChange}
      />
      <RequesterTable
        isLoaded={isLoaded}
        page={pagination.page}
        size={pagination.size}
        requesterList={requesterList}
        fetchRequesterList={fetchRequesterList}
        filter={filters}
      />
      <Pagination {...pagination} handlePageChange={handlePageChange} />
    </>
  );
};
export default RequestList;
