import React from 'react';
import LiveDataCards from 'src/components/LiveDataCards';
import { IGlobalCount } from 'src/services/api/covidCases';
import * as routes from 'src/constants/routes';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

interface IGlobalCasesProps {
  liveData: IGlobalCount;
}

const GlobalCases: React.FC<IGlobalCasesProps> = props => {
  const { liveData } = props;

  return (
    <div style={{ marginTop: '20px' }}>
      <LiveDataCards
        totalConfirmed={liveData.confirmedTotal}
        totalRecovered={liveData.recoveredTotal}
        totalDeaths={liveData.deathTotal}
      />
      <div className={'text-right'} style={{ marginTop: '20px' }}>
        <Link to={routes.COVID_CASES_UPDATE_GLOBAL}>
          <Button variant="info">Update Global Data</Button>
        </Link>
      </div>
    </div>
  );
};

export default GlobalCases;
