import React from 'react';
import { INews, updateNewsAPI } from 'src/services/api/news';
import NewsFormElements, {
  INewsFormValues
} from '../common/Form/NewsFormElements';
import { InjectedFormikProps, withFormik } from 'formik';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as routes from 'src/constants/routes';
import * as noty from 'src/utils/noty';
import { newsSchema } from 'src/validations/news';

interface IEditNewsFormProps {
  news: INews;
}

const EditNewsForm: React.FC<InjectedFormikProps<
  IEditNewsFormProps,
  INewsFormValues
>> = props => {
  const { handleSubmit, isSubmitting } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <NewsFormElements {...props} />
      <Row>
        <Col sm="12" className="text-right mt-3">
          <Form.Group>
            <Link to={routes.NEWS}>
              <Button variant="link" className="mr-4">
                Cancel
              </Button>
            </Link>
            <Button
              variant="primary"
              type="submit"
              className="px-4"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Updating...' : 'Update News'}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

const WrappedWithFormik = withFormik<IEditNewsFormProps, INewsFormValues>({
  enableReinitialize: true,
  mapPropsToValues(props) {
    const { news } = props;

    return {
      title: news.title,
      description: news.description,
      imageUrl: news.imageUrl,
      source: news.source,
      type: {
        label: news.type,
        value: news.type
      },
      uploadedAt: news.uploadedAt,
      url: news.url
    };
  },
  validationSchema: newsSchema,
  handleSubmit: async (values, { props, setSubmitting }) => {
    const payload: INews = {
      type: values.type.value.toString(),
      title: values.title,
      description: values.description,
      imageUrl: values.imageUrl
        ? values.imageUrl
        : 'https://youngfreeflorida.com/wp-content/uploads/2019/06/Marketplace-Lending-News.jpg',
      source: values.source,
      uploadedAt: values.uploadedAt,
      url: values.url
    };

    try {
      setSubmitting(true);
      await updateNewsAPI(payload, props.news._id || '');
      noty.success('News updated successfully');
      window.location.assign(routes.NEWS);
    } catch (error) {
      noty.error('Failed to update news');
    } finally {
      setSubmitting(false);
    }
  }
});

export default WrappedWithFormik(EditNewsForm);
