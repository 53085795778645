import React, { useEffect, useState } from 'react';
import { INews, fetchTopNewsAPI, deleteNewsAPI } from 'src/services/api/news';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as noty from 'src/utils/noty';
import ConfirmationBox from 'src/components/ConfrimationBox';
import { getLiteralDate } from 'src/utils/date';

const TopNews = () => {
  const [topNews, setTopNews] = useState<INews | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    setIsLoaded(false);
    fetchTopNews();
  }, []);

  const fetchTopNews = async () => {
    try {
      const response = await fetchTopNewsAPI();
      setTopNews(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoaded(true);
    }
  };

  const deleteNews = async () => {
    try {
      if (topNews && topNews._id) {
        await deleteNewsAPI(topNews._id);
        noty.success('News deleted successfully');
        toggleDeleteModal();
      }
    } catch (e) {
      noty.error('Failed to delete news');
      console.log(e);
    }
  };

  const toggleDeleteModal = (id?: string) => {
    setDeleteModal(!showDeleteModal);
    fetchTopNews();
  };

  return (
    <div className="container-fluid">
      {isLoaded && topNews ? (
        <>
          <h4 style={{ margin: '20px 0px' }}>Current Top News</h4>
          <h5>{topNews.title}</h5>
          <p>{topNews.description}</p>
          <p>
            News URL:
            <a href={topNews.url} target="_blank">
              {topNews.url}
            </a>
          </p>
          <p>
            Image URL:
            <a href={topNews.imageUrl} target="_blank">
              {topNews.imageUrl}
            </a>
          </p>
          <p>Source: {topNews.source}</p>
          <p>Posted Date: {getLiteralDate(topNews.uploadedAt)}</p>
          <Link to={`/news/edit/${topNews._id}`}>
            <Button>Edit</Button>
          </Link>
          <Button
            className={'ml-1'}
            variant="danger"
            onClick={() => toggleDeleteModal(topNews._id)}
          >
            Delete
          </Button>
        </>
      ) : topNews === null ? (
        'No Record Found...'
      ) : (
        'Loading...'
      )}

      {showDeleteModal && (
        <ConfirmationBox
          handleDelete={deleteNews}
          toggleModal={toggleDeleteModal}
          showModal={showDeleteModal}
        >
          Are you sure?
        </ConfirmationBox>
      )}
    </div>
  );
};

export default TopNews;
