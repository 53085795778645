import React from 'react';
import Router from './Router';

const CovidCases = () => {
  return (
    <div className="container-fluid">
      <Router />
    </div>
  );
};

export default CovidCases;
