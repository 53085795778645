import React from 'react';
import { InjectedFormikProps } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';

export interface ICovidCasesFormValues {
  testedTotal?: number;
  confirmedTotal: number;
  recoveredTotal: number;
  deathTotal: number;
}

const CovidCasesFormElements: React.FC<InjectedFormikProps<
  {},
  ICovidCasesFormValues
>> = props => {
  const { values, touched, errors, handleChange } = props;

  return (
    <div>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Total Tested
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'testedTotal'}
            type={'number'}
            value={values.testedTotal?.toString()}
            placeholder={'Total number of tests'}
            onChange={handleChange}
            disabled={!values.testedTotal}
          />
          {errors.testedTotal && touched.testedTotal && (
            <small className={'text-danger'}>{errors.testedTotal}</small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Total Confirmed
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'confirmedTotal'}
            type={'number'}
            value={values.confirmedTotal.toString()}
            placeholder={'Total number of confirmed cases'}
            onChange={handleChange}
          />
          {errors.confirmedTotal && touched.confirmedTotal && (
            <small className={'text-danger'}>{errors.confirmedTotal}</small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Total Recovered
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'recoveredTotal'}
            type={'number'}
            value={values.recoveredTotal.toString()}
            placeholder={'Total number of recovered cases'}
            onChange={handleChange}
          />
          {errors.recoveredTotal && touched.recoveredTotal && (
            <small className={'text-danger'}>{errors.recoveredTotal}</small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Total Deaths
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'deathTotal'}
            type={'number'}
            value={values.deathTotal.toString()}
            placeholder={'Total number of deaths'}
            onChange={handleChange}
          />
          {errors.deathTotal && touched.deathTotal && (
            <small className={'text-danger'}>{errors.deathTotal}</small>
          )}
        </Col>
      </Form.Group>
    </div>
  );
};

export default CovidCasesFormElements;
