import React, { FC } from 'react';
import { withFormik, InjectedFormikProps } from 'formik';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as noty from 'src/utils/noty';
import RequesterFormElements, {
  IRequesterFormElementsValues
} from '../common/RequesterFormElements';
import * as routes from 'src/constants/routes';
import { getAllSupportItems, getSupportersIds } from 'src/helpers/support';
import { createRequestAPI } from 'src/services/api/frontLine';
import { supportRequestorsSchema } from 'src/validations/requester';

const AddRequester: FC<InjectedFormikProps<
  {},
  IRequesterFormElementsValues
>> = props => {
  const { handleSubmit } = props;

  return (
    <div className="container">
      <h4 className="text-center" style={{ margin: '20px' }}>
        Add Requester
      </h4>
      <Form onSubmit={handleSubmit}>
        <RequesterFormElements {...props} />
        <Row>
          <Col sm="12" className="text-right mt-3">
            <Form.Group controlId="terminalAction">
              <Link to={routes.FRONTLINE_REQUESTER}>
                <Button variant="link" className="mr-4">
                  Cancel
                </Button>
              </Link>

              <Button variant="primary" type="submit" className="px-4">
                Add Requester
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
const WrappedWithFormik = withFormik<{}, IRequesterFormElementsValues>({
  enableReinitialize: true,
  mapPropsToValues(props) {
    return {
      name: '',
      email: '',
      phone: '',
      organization: '',
      fulfilledBy: [],
      isFulfilled: null,
      isVerified: null,
      location: '',
      otherSupportItems: '',
      requestedItems: [],
      additionalSupport: ''
    };
  },
  validationSchema: supportRequestorsSchema,
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      const {
        name,
        organization,
        location,
        fulfilledBy,
        email,
        phone,
        otherSupportItems,
        requestedItems,
        isVerified,
        isFulfilled,
        additionalSupport,
        ...rest
      } = values;
      const allRequestedItems = getAllSupportItems(
        otherSupportItems,
        requestedItems
      );
      const payload = {
        name,
        organization,
        location,
        fulfilledBy: fulfilledBy
          ? (getSupportersIds(fulfilledBy) as Array<string>)
          : [],

        contact: {
          email: email,
          phone: phone
        },
        isVerified: isVerified ? (isVerified.value as boolean) : false,
        isFulfilled: isFulfilled ? (isFulfilled.value as boolean) : false,
        requestedItems: allRequestedItems,
        others: additionalSupport
      };
      await createRequestAPI(payload);
      noty.success('Requester details has been added successfully');
      window.location.assign(routes.FRONTLINE_REQUESTER);
    } catch (e) {
      noty.error('Failed to add requester details');
      console.log(e);
    }
  }
});
export default WrappedWithFormik(AddRequester);
