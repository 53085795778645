import * as yup from 'yup';

export const newsSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .required('Title is required'),
  description: yup
    .string()
    .trim()
    .required('Description is required'),
  source: yup
    .string()
    .trim()
    .required('Source is required'),
  url: yup
    .string()
    .trim()
    .required('URL is required'),
  imageUrl: yup.string().trim(),
  uploadedAt: yup
    .string()
    .trim()
    .required('Uploaded Date is required'),
  type: yup
    .string()
    .nullable()
    .required('News Type is required')
});
