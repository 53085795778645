import React, { FC } from 'react';

import { IHospital } from 'src/services/api/hospital';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ILanguage } from 'src/services/api/hospital';

interface IHospitalTableRowProps {
  index: number;
  hospital: IHospital;
  toggleDeleteModal: (id?: string) => void;
}

const HospitalTableRow: FC<IHospitalTableRowProps> = props => {
  const {
    index,
    hospital: {
      _id,
      name,
      location,
      contact,
      totalBeds,
      numIsolationBeds,
      icu,
      ventilators
    }
  } = props;

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{name.en}</td>
        <td>{location ? location.en : '-'}</td>

        <td>
          {contact
            ? contact.map((value: ILanguage, index) => (
                <span key={index}>
                  {value.en} {index === contact.length - 1 ? ' ' : ', '}
                </span>
              ))
            : '-'}
        </td>

        <td>{totalBeds ? totalBeds : '-'}</td>
        <td>{icu ? icu : '-'}</td>
        <td>{ventilators ? ventilators : '-'}</td>
        <td>{numIsolationBeds ? numIsolationBeds : '-'}</td>

        <td>
          <Link to={`/hospital/edit/${_id}`}>
            <Button>Edit</Button>
          </Link>

          <Button
            className={'ml-1'}
            variant="danger"
            onClick={() => {
              props.toggleDeleteModal(_id);
            }}
          >
            Delete
          </Button>
        </td>
      </tr>
    </>
  );
};

export default HospitalTableRow;
