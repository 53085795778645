import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { INews, fetchNewsByIdAPI } from 'src/services/api/news';
import EditNewsForm from './EditNewsForm';

const EditNews: React.FC<{}> = () => {
  const { id = '' } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [formData, setFormData] = useState<INews>({} as INews);

  useEffect(() => {
    fetchNewsById();
  }, []);

  const fetchNewsById = async () => {
    try {
      const response = await fetchNewsByIdAPI(id);
      setFormData(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoaded(true);
    }
  };

  return (
    <div className="container">
      <h4 className="text-center" style={{ margin: '20px' }}>
        Update News
      </h4>
      {isLoaded ? (
        <EditNewsForm news={formData} />
      ) : (
        <p className="text-center">Loading...</p>
      )}
    </div>
  );
};

export default EditNews;
