import * as yup from 'yup';

export const supportRequestorsSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  organization: yup.string().required('Organization is required'),
  requestedItems: yup.array().required('Requested item is required')
  // isVerified: yup.boolean().nullable(),
  // isVFulfilled: yup.boolean().nullable(),
});
