export interface IOptions {
  label: string;
  value: string | number | boolean;
  nepali?: string;
}
export interface IOptionWithNepali extends IOptions {
  nepali: string;
}

export const HospitalType: IOptions[] = [
  { label: 'Private', value: 'Private', nepali: 'निजी' },
  { label: 'Government', value: 'Government', nepali: 'सरकारी' }
];

export const HospitalTags: IOptions[] = [
  { label: 'Hub', value: 'hub', nepali: '' }
];

export const Days: IOptions[] = [
  { value: 'Sunday', label: 'Sunday', nepali: 'आइतवार' },
  { value: 'Monday', label: 'Monday', nepali: 'सोमबार' },
  { value: 'Tuesday', label: 'Tuesday', nepali: 'मंगलबार' },
  { value: 'Wednesday', label: 'Wednesday', nepali: 'बुधवार' },
  { value: 'Thursday', label: 'Thursday', nepali: 'बिहीबार' },
  { value: 'Friday', label: 'Friday', nepali: 'शुक्रवार' },
  { value: 'Saturday', label: 'Saturday', nepali: 'शनिबार' }
];

export const YesNoOption: IOptions[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];
export const ProvinceOptions: IOptions[] = [
  { label: 'Province-01', value: '1', nepali: 'प्रदेश-१' },
  { label: 'Province-02', value: '2', nepali: 'प्रदेश-२' },
  { label: 'Province-03', value: '3', nepali: 'प्रदेश-३' },
  { label: 'Province-04', value: '4', nepali: 'प्रदेश-४' },
  { label: 'Province-05', value: '5', nepali: 'प्रदेश-५' },
  { label: 'Province-06', value: '6', nepali: 'प्रदेश-६' },
  { label: 'Province-07', value: '7', nepali: 'प्रदेश-७' }
];

export const SupportItemsList: IOptions[] = [
  {
    label: 'PPE(Personal Protective Equipment)',
    value: 'PPE(Personal Protective Equipment)'
  },
  { label: 'N95 Masks', value: 'N95 Masks' },
  { label: 'Regular Masks', value: 'Regular Masks' },
  { label: 'Gloves', value: 'Gloves' },
  { label: 'Gown', value: 'Gown' },
  { label: 'Eye protection glasses', value: 'Eye protection glasses' },
  { label: 'Face shield', value: 'Face shield' },
  { label: 'Hair cap', value: 'Hair cap' },
  { label: 'Boots', value: 'Boots' },
  { label: 'Shoe coverups', value: 'Shoe coverups' },
  { label: 'Sanitizer', value: 'Sanitizer' }
];

export const RequestItemsList: IOptions[] = [
  ...SupportItemsList,
  { label: 'Training on how to use PPE', value: 'Training on how to use PPE' }
];

export const NewsTypeOptions: IOptions[] = [
  { label: 'GLOBAL', value: 'GLOBAL' },
  { label: 'NEPAL', value: 'NEPAL' },
  { label: 'TOP NEWS', value: 'TOP' },
  { label: 'TIPS', value: 'TIP' }
];

export const monthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
