import * as yup from 'yup';

export const hospitalSchema = yup.object().shape({
  name_en: yup
    .string()
    .nullable()
    .required('Name is required'),
  nameSlug: yup
    .string()
    .nullable()
    .required('Slug name is required'),
  hospitalType: yup
    .string()
    .nullable()
    .required('Hospital type is required'),
  openDays_en: yup
    .string()
    .nullable()
    .required('Open day  is required'),
  location_en: yup
    .string()
    .nullable()
    .required('Location is required'),
  mapLink: yup
    .string()
    .nullable()
    .required('Map link is required'),
  totalBeds: yup
    .number()
    .nullable()
    .required('Total number of bed is required'),
  icu: yup
    .number()
    .nullable()
    .required('ICU is required'),
  ventilators: yup
    .number()
    .nullable()
    .required('Ventilators is required'),
  numIsolationBeds: yup
    .number()
    .nullable()
    .required('Isolation Beds is required'),
  availableBeds: yup
    .number()
    .nullable()
    .required('Available beds is required'),
  govtDesignated: yup
    .string()
    .nullable()
    .required('Govt designation is required'),
  province: yup
    .string()
    .nullable()
    .required('Province is required'),
  district: yup
    .string()
    .nullable()
    .required('District is required'),
  contact_en: yup
    .string()
    .nullable()
    .required('Contact is required'),
  availableTime_en: yup
    .string()
    .nullable()
    .required('Available time is required'),
  focalPoint_en: yup.string().nullable(),
  testingProcess_en: yup.string().nullable(),
  covidTest: yup
    .string()
    .nullable()
    .required('Covid test is required'),
  isVerified: yup
    .string()
    .nullable()
    .required('Is verified is required'),
  authorizedCovidTest: yup
    .string()
    .nullable()
    .required('Authorized Covid Test is required'),
  priority: yup.number().nullable()
});
