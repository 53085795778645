import React, { useEffect, useState } from 'react';
import { InjectedFormikProps } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';
import axios, { Canceler } from 'axios';
import { ValueType, ActionMeta } from 'react-select';

import { HospitalType, HospitalTags } from 'src/constants/options';
import { IOptions, YesNoOption, ProvinceOptions } from 'src/constants/options';
import { IDistrict } from 'src/constants/common';
import * as CommonAPI from 'src/services/api/common.api';
import CustomSelectInput from 'src/components/Forms/CustomSelectInput';

export interface IHospitalFormElementsValues {
  name_en: string;
  name_np: string;
  nameSlug: string;
  hospitalType: IOptions | null;
  hospitalTag: Array<IOptions>;
  openDays_en: string;
  openDays_np: string;
  location_en: string;
  location_np: string;
  mapLink: string;
  totalBeds: string;
  icu: string;
  ventilators: string;
  numIsolationBeds: string;
  availableBeds: string;
  govtDesignated: IOptions | null;
  province: IOptions | null;
  district: IOptions | null;
  contact_en: string;
  contact_np: string;
  availableTime_en: string;
  availableTime_np: string;
  focalPoint_en: string;
  focalPoint_np: string;
  testingProcess_en: string;
  testingProcess_np: string;
  covidTest: IOptions | null;
  isVerified: IOptions | null;
  authorizedCovidTest: IOptions | null;
  priority: string;
}
interface IDistrictListOption {
  en: string;
  np: string;
}
const HospitalFormElements: React.FC<InjectedFormikProps<
  {},
  IHospitalFormElementsValues
>> = props => {
  const { values, touched, errors, handleChange, setFieldValue } = props;
  const [districtListOption, setDistrictList] = useState<IOptions[]>([]);
  const [loadingDistrict, setLoadingDistrict] = useState<boolean>(true);
  let cancel: Canceler | null;

  useEffect(() => {
    // update district
    setLoadingDistrict(true);
    if (cancel) {
      cancel();
    }

    const cancelToken = new axios.CancelToken(c => {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });
    const province = values.province ? values.province.value.toString() : '3';
    CommonAPI.getDistritByProvince(province, cancelToken).then(
      (districtList: IDistrict[]) => {
        const districtOptions = districtList.map(d => ({
          label: d.name.en,
          value: d.name.en,
          nepali: d.name.np
        }));
        cancel = null;
        setDistrictList(districtOptions);
        setLoadingDistrict(false);
      },
      e => {
        if (axios.isCancel(e)) {
          console.error('Request is canceled.');
        } else {
          setDistrictList([]);
          setLoadingDistrict(false);
        }
      }
    );
    return () => {
      if (cancel) {
        cancel();
      }
    };
  }, [values.province]);

  const handleCustomChange = (
    value: ValueType<IOptions> | ValueType<Array<IOptions>>,
    action?: ActionMeta
  ) => {
    if (action && action.name) {
      setFieldValue(action.name, value);
    }
  };
  const handleProvinceChange = (
    value: ValueType<IOptions>,
    action?: ActionMeta
  ) => {
    if (action && action.name) {
      setFieldValue(action.name, value);
      setFieldValue('district', null);
    }
  };

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Slug Name
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'nameSlug'}
            type="text"
            value={values.nameSlug}
            placeholder="Slug Name"
            onChange={handleChange}
          />
          {errors['name_en'] && touched['name_en'] ? (
            <small className={'text-danger'}>{errors['name_en']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Name
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'name_en'}
            value={values.name_en}
            onChange={handleChange}
            type="text"
            placeholder="English"
          />
          {errors['name_en'] && touched['name_en'] ? (
            <small className={'text-danger'}>{errors['name_en']}</small>
          ) : (
            ''
          )}
        </Col>
        <Col sm="5">
          <Form.Control
            name={'name_np'}
            value={values.name_np}
            onChange={handleChange}
            type="text"
            placeholder="Nepali"
          />
          {errors['name_np'] && touched['name_np'] ? (
            <small className={'text-danger'}>{errors['name_np']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Hospital Type
        </Form.Label>
        <Col sm="5">
          <CustomSelectInput
            name={'hospitalType'}
            placeholder={'Select hosiptal type'}
            handleChange={handleCustomChange}
            selectedValue={values.hospitalType}
            options={HospitalType}
            isClearable={true}
          />
          {errors['hospitalType'] && touched['hospitalType'] ? (
            <small className={'text-danger'}>{errors['hospitalType']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Hospital Tag
        </Form.Label>
        <Col sm="5">
          <CustomSelectInput
            name={'hospitalTag'}
            placeholder={'Select hosiptal tag'}
            handleChange={handleCustomChange}
            selectedValue={values.hospitalTag}
            options={HospitalTags}
            isClearable={true}
            isMulti={true}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Open day
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'openDays_en'}
            value={values.openDays_en}
            onChange={handleChange}
            type="text"
            placeholder="English"
          />
          {errors['openDays_en'] && touched['openDays_en'] ? (
            <small className={'text-danger'}>{errors['openDays_en']}</small>
          ) : (
            ''
          )}
        </Col>
        <Col sm="5">
          <Form.Control
            name={'openDays_np'}
            value={values.openDays_np}
            onChange={handleChange}
            type="text"
            placeholder="Nepali"
          />
          {errors['openDays_np'] && touched['openDays_np'] ? (
            <small className={'text-danger'}>{errors['openDays_np']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Location
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'location_en'}
            type="text"
            value={values.location_en}
            placeholder="English"
            onChange={handleChange}
          />
          {errors['location_en'] && touched['location_en'] ? (
            <small className={'text-danger'}>{errors['location_en']}</small>
          ) : (
            ''
          )}
        </Col>
        <Col sm="5">
          <Form.Control
            name={'location_np'}
            value={values.location_np}
            onChange={handleChange}
            type="text"
            placeholder="Nepali"
          />
          {errors['location_np'] && touched['location_np'] ? (
            <small className={'text-danger'}>{errors['location_np']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Map link
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'mapLink'}
            type="text"
            value={values.mapLink}
            onChange={handleChange}
            placeholder="Map link"
          />
          {errors['mapLink'] && touched['mapLink'] ? (
            <small className={'text-danger'}>{errors['mapLink']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Total beds
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'totalBeds'}
            type="number"
            placeholder="Total number of bed"
            value={values.totalBeds}
            onChange={handleChange}
          />
          {errors['totalBeds'] && touched['totalBeds'] ? (
            <small className={'text-danger'}>{errors['totalBeds']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          ICU
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'icu'}
            type="number"
            value={values.icu}
            onChange={handleChange}
            placeholder="Total number of ICU"
          />
          {errors['icu'] && touched['icu'] ? (
            <small className={'text-danger'}>{errors['icu']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Ventilators
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'ventilators'}
            type="number"
            value={values.ventilators}
            onChange={handleChange}
            placeholder="Total number of ventilators"
          />
          {errors['ventilators'] && touched['ventilators'] ? (
            <small className={'text-danger'}>{errors['ventilators']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Isolation beds
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'numIsolationBeds'}
            type="number"
            value={values.numIsolationBeds}
            onChange={handleChange}
            placeholder="Total number of isolation beds"
          />
          {errors['numIsolationBeds'] && touched['numIsolationBeds'] ? (
            <small className={'text-danger'}>
              {errors['numIsolationBeds']}
            </small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Available Beds
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'availableBeds'}
            type="number"
            onChange={handleChange}
            value={values.availableBeds}
            placeholder="Total number of available beds"
          />
          {errors['availableBeds'] && touched['availableBeds'] ? (
            <small className={'text-danger'}>{errors['availableBeds']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Govt Designation
        </Form.Label>
        <Col sm="5">
          <CustomSelectInput
            name={'govtDesignated'}
            placeholder={'Select gov designation'}
            selectedValue={values.govtDesignated}
            handleChange={handleCustomChange}
            options={YesNoOption}
            isClearable={true}
          />
          {errors['govtDesignated'] && touched['govtDesignated'] ? (
            <small className={'text-danger'}>{errors['govtDesignated']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Province
        </Form.Label>
        <Col sm="5">
          <CustomSelectInput
            name={'province'}
            placeholder={'Select province'}
            selectedValue={values.province}
            handleChange={handleProvinceChange}
            options={ProvinceOptions}
            isClearable={true}
          />
          {errors['province'] && touched['province'] ? (
            <small className={'text-danger'}>{errors['province']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          District
        </Form.Label>
        <Col sm="5">
          <CustomSelectInput
            name={'district'}
            placeholder={'Select district'}
            selectedValue={values.district}
            handleChange={handleCustomChange}
            options={districtListOption}
            isLoading={loadingDistrict}
            isClearable={true}
          />
          {errors['district'] && touched['district'] ? (
            <small className={'text-danger'}>{errors['district']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Contact
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'contact_en'}
            value={values.contact_en}
            onChange={handleChange}
            type="text"
            placeholder="English"
          />
          {errors['contact_en'] && touched['contact_en'] ? (
            <small className={'text-danger'}>{errors['contact_en']}</small>
          ) : (
            ''
          )}
        </Col>
        <Col sm="5">
          <Form.Control
            name={'contact_np'}
            value={values.contact_np}
            onChange={handleChange}
            type="text"
            placeholder="Nepali"
          />
          {errors['contact_np'] && touched['contact_np'] ? (
            <small className={'text-danger'}>{errors['contact_np']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Available Time
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'availableTime_en'}
            type="text"
            value={values.availableTime_en}
            onChange={handleChange}
            placeholder="English"
          />
          {errors['availableTime_en'] && touched['availableTime_en'] ? (
            <small className={'text-danger'}>
              {errors['availableTime_en']}
            </small>
          ) : (
            ''
          )}
        </Col>
        <Col sm="5">
          <Form.Control
            name={'availableTime_np'}
            type="text"
            value={values.availableTime_np}
            onChange={handleChange}
            placeholder="Nepali"
          />
          {errors['availableTime_np'] && touched['availableTime_np'] ? (
            <small className={'text-danger'}>
              {errors['availableTime_np']}
            </small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Focal Point
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'focalPoint_en'}
            type="text"
            value={values.focalPoint_en}
            onChange={handleChange}
            placeholder="English"
          />
          {errors['focalPoint_en'] && touched['focalPoint_en'] ? (
            <small className={'text-danger'}>{errors['focalPoint_en']}</small>
          ) : (
            ''
          )}
        </Col>
        <Col sm="5">
          <Form.Control
            name={'focalPoint_np'}
            type="text"
            value={values.focalPoint_np}
            onChange={handleChange}
            placeholder="Nepali"
          />
          {errors['focalPoint_np'] && touched['focalPoint_np'] ? (
            <small className={'text-danger'}>{errors['focalPoint_np']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Testing Process
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'testingProcess_en'}
            type="text"
            onChange={handleChange}
            value={values.testingProcess_en}
            placeholder="English"
          />
          {errors['testingProcess_en'] && touched['testingProcess_en'] ? (
            <small className={'text-danger'}>
              {errors['testingProcess_en']}
            </small>
          ) : (
            ''
          )}
        </Col>
        <Col sm="5">
          <Form.Control
            name={'testingProcess_np'}
            type="text"
            onChange={handleChange}
            value={values.testingProcess_np}
            placeholder="Nepali"
          />
          {errors['testingProcess_np'] && touched['testingProcess_np'] ? (
            <small className={'text-danger'}>
              {errors['testingProcess_np']}
            </small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Covid Test
        </Form.Label>
        <Col sm="5">
          <CustomSelectInput
            name={'covidTest'}
            placeholder={'Select Covid Test'}
            handleChange={handleCustomChange}
            selectedValue={values.covidTest}
            options={YesNoOption}
            isClearable={true}
          />
          {errors['covidTest'] && touched['covidTest'] ? (
            <small className={'text-danger'}>{errors['covidTest']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Verified
        </Form.Label>
        <Col sm="5">
          <CustomSelectInput
            name={'isVerified'}
            placeholder={'Select'}
            selectedValue={values.isVerified}
            handleChange={handleCustomChange}
            options={YesNoOption}
            isClearable={true}
          />
          {errors['isVerified'] && touched['isVerified'] ? (
            <small className={'text-danger'}>{errors['isVerified']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Authorized Covid Test
        </Form.Label>
        <Col sm="5">
          <CustomSelectInput
            name={'authorizedCovidTest'}
            placeholder={'Select'}
            selectedValue={values.authorizedCovidTest}
            handleChange={handleCustomChange}
            options={YesNoOption}
            isClearable={true}
          />
          {errors['authorizedCovidTest'] && touched['authorizedCovidTest'] ? (
            <small className={'text-danger'}>
              {errors['authorizedCovidTest']}
            </small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Priority
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'priority'}
            type="number"
            value={values.priority}
            onChange={handleChange}
            placeholder="Priority"
          />
          {errors['priority'] && touched['priority'] ? (
            <small className={'text-danger'}>{errors['priority']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
    </>
  );
};

export default HospitalFormElements;
