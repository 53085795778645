import React from 'react';
import Router from './Router';
import Navbar from 'src/components/Navbar';

const Dashboard: React.FC<{}> = () => {
  return (
    <>
      <Navbar />
      <Router />
    </>
  );
};

export default Dashboard;
