import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { withFormik, InjectedFormikProps } from 'formik';
import { Link } from 'react-router-dom';

import HospitalFormElements, {
  IHospitalFormElementsValues
} from '../common/hospitalFormElements';
import { createHospitalCapacity } from 'src/services/api/hospital';
import { hospitalSchema } from 'src/validations/hospital';
import { IHospital } from 'src/services/api/hospital';
import * as noty from 'src/utils/noty';
import * as routes from 'src/constants/routes';

const AddHospital: React.FC<InjectedFormikProps<
  {},
  IHospitalFormElementsValues
>> = props => {
  const { handleSubmit } = props;
  return (
    <div className="container">
      <h4 className="text-center" style={{ margin: '20px' }}>
        Add Hospital
      </h4>
      <Form onSubmit={handleSubmit}>
        <HospitalFormElements {...props} />
        <Row>
          <Col sm="12" className="text-right mt-3">
            <Form.Group controlId="terminalAction">
              <Link to={routes.HOSPITAL}>
                <Button variant="link" className="mr-4">
                  Cancel
                </Button>
              </Link>

              <Button variant="primary" type="submit" className="px-4">
                Add Hospital
              </Button>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
const WrappedWithFormik = withFormik<{}, IHospitalFormElementsValues>({
  enableReinitialize: true,
  mapPropsToValues(props) {
    return {
      name_en: '',
      name_np: '',
      nameSlug: '',
      hospitalType: null,
      hospitalTag: [],
      openDays_en: '',
      openDays_np: '',
      location_en: '',
      location_np: '',
      mapLink: '',
      totalBeds: '',
      icu: '',
      ventilators: '',
      numIsolationBeds: '',
      availableBeds: '',
      govtDesignated: null,
      province: null,
      district: null,
      contact_en: '',
      contact_np: '',
      availableTime_en: '',
      availableTime_np: '',
      focalPoint_en: '',
      focalPoint_np: '',
      testingProcess_en: '',
      testingProcess_np: '',
      covidTest: null,
      isVerified: null,
      authorizedCovidTest: null,
      priority: ''
    };
  },
  validationSchema: hospitalSchema,
  handleSubmit: async (values, { props, setSubmitting }) => {
    const province = {
      code: values.province ? (values.province.value as string) : '',
      name: {
        np: values.province ? values.province.nepali : '',
        en: values.province ? values.province.label : ''
      }
    };
    const availableTimeEn = values.availableTime_en.split(',');
    const availableTimeNp = values.availableTime_np.split(',');
    const availableTime = availableTimeEn.map(
      (value: string, index: number) => {
        return {
          en: value,
          np: availableTimeNp[index]
        };
      }
    );

    const contact_en = values.contact_en.split(',');
    const contact_np = values.contact_np.split(',');
    const contact = contact_en.map((value: string, index: number) => {
      return {
        en: value,
        np: contact_np[index] || ''
      };
    });
    // payload
    const payload: IHospital = {
      coordinates: [],
      name: {
        en: values.name_en,
        np: values.name_np
      },
      hospitalType: {
        en: values.hospitalType ? (values.hospitalType.value as string) : '',
        np: values.hospitalType ? values.hospitalType.nepali : ''
      },
      tags: values.hospitalTag
        ? values.hospitalTag.map(tag => tag.value as string)
        : [],
      availableTime,
      openDays: {
        en: values.openDays_en,
        np: values.openDays_np
      },
      location: {
        en: values.location_en,
        np: values.location_np
      },
      mapLink: values.mapLink,
      covidTest: values.covidTest ? (values.covidTest.value as boolean) : false,
      testingProcess: {
        en: values.testingProcess_en,
        np: values.testingProcess_np
      },
      govtDesignated: values.govtDesignated
        ? (values.govtDesignated.value as boolean)
        : false,
      numIsolationBeds: +values.numIsolationBeds,
      focalPoint: {
        en: values.focalPoint_en,
        np: values.focalPoint_np
      },
      contact,
      district: {
        en: values.district ? (values.district.value as string) : '',
        np: values.district ? (values.district.nepali as string) : ''
      },
      nameSlug: values.nameSlug,
      province,
      isVerified: values.isVerified
        ? (values.isVerified.value as boolean)
        : false,
      totalBeds: +values.totalBeds,
      availableBeds: +values.availableBeds,
      icu: +values.icu,
      ventilators: +values.ventilators,
      authorizedCovidTest: values.authorizedCovidTest
        ? (values.authorizedCovidTest.value as boolean)
        : false,
      priority: values.priority ? +values.priority : null
    };
    try {
      await createHospitalCapacity(payload);
      noty.success('Hospital capacity created successfully');
      window.location.assign(routes.HOSPITAL);
    } catch (e) {
      noty.error('Failed to create hositpal capacity');
      console.log(e);
    }
  }
});

export default WrappedWithFormik(AddHospital);
