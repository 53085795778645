import React, { FC, useEffect, useState } from 'react';
import { InjectedFormikProps } from 'formik';
import { useParams } from 'react-router-dom';

import { IHospitalFormElementsValues } from '../common/hospitalFormElements';
import {
  IHospital,
  fetchHospitalCapacityById
} from 'src/services/api/hospital';
import EditHospitalForm from './form';

const EditHospital: FC<InjectedFormikProps<
  {},
  IHospitalFormElementsValues
>> = props => {
  const { id = '' } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [formData, setFormData] = useState<IHospital>({} as IHospital);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchHospitalCapacityById(id);
        console.log(response);
        setFormData(response);
        setIsLoaded(true);
      } catch (e) {
        throw e;
      }
    })();
  }, []);
  return (
    <div className="container">
      <h4 className="text-center" style={{ margin: '20px' }}>
        Update Hospital
      </h4>
      {isLoaded ? (
        <EditHospitalForm hospital={formData} />
      ) : (
        <p className="text-center">Loading...</p>
      )}
    </div>
  );
};

export default EditHospital;
