import React, { useState, useEffect } from 'react';
import { INews, fetchNewsAPI } from 'src/services/api/news';
import { IMeta, initialMeta } from 'src/interface/common';
import NewsTable from 'src/routes/dashboard/News/common/Table/NewsTable';
import Pagination from 'src/components/Pagination';

const GlobalNews = () => {
  const [globalNews, setGlobalNews] = useState<INews[]>([]);
  const [meta, setMeta] = useState<IMeta>(initialMeta);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    fetchGlobalNews();
  }, [meta.page]);

  const fetchGlobalNews = async () => {
    try {
      const response = await fetchNewsAPI({
        page: meta.page,
        size: meta.size,
        type: 'GLOBAL'
      });
      setMeta(response.meta);
      setGlobalNews(response.docs);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoaded(true);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setMeta(prevMetaState => ({
      ...prevMetaState,
      page: pageNumber - 1
    }));
  };

  return (
    <div className="container-fluid">
      <NewsTable
        newsList={globalNews}
        isLoaded={isLoaded}
        meta={meta}
        refresh={fetchGlobalNews}
      />
      <Pagination
        page={meta.page + 1}
        pages={meta.totalPages}
        size={meta.size}
        total={meta.totalItems}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default GlobalNews;
