export const letterLimiter = (text: string, limit: number) => {
  if (text.length <= limit) {
    return text;
  } else {
    const t = text.substring(0, limit);
    return t + '...';
  }
};

export const setCommas = (input: number) => {
  return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
