import React, { useContext, useState } from 'react';
import { Table } from 'react-bootstrap';

import { HospitalContext } from '../HospitalMain';
import HospitalTableRow from './HospitalTableRow';
import ConfirmationBox from 'src/components/ConfrimationBox';
import { deleteHospitalCapacity } from 'src/services/api/hospital';
import * as noty from 'src/utils/noty';
interface IHostipalTable {
  page: number;
  size: number;
}
const HospitalTable: React.FC<IHostipalTable> = props => {
  const { isLoaded, hospitalList = [] } = useContext(HospitalContext);
  const [showDeleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');

  const toggleDeleteModal = (id?: string) => {
    if (id) {
      setDeleteId(id);
    }
    setDeleteModal(!showDeleteModal);
  };
  const deleteHospital = async () => {
    try {
      await deleteHospitalCapacity(deleteId);
      noty.success('Hospital capacity deleted successfully');
      toggleDeleteModal();
    } catch (e) {
      noty.error('Failed to update hositpal capacity');
      console.log(e);
    }
  };
  return (
    <>
      <Table style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Name of Hospital</th>
            <th>Address</th>
            <th>Contact</th>
            <th>No. of beds</th>
            <th>No. of ICU beds</th>
            <th>No. of ventilators</th>
            <th>No. of isolation beds</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoaded ? (
            hospitalList.length > 0 ? (
              hospitalList.map((hospital, index: number) => {
                const ind = (props.page - 1) * props.size + index;
                return (
                  <HospitalTableRow
                    key={hospital._id}
                    index={ind}
                    hospital={hospital}
                    toggleDeleteModal={toggleDeleteModal}
                  />
                );
              })
            ) : (
              <tr>
                <td>No records found</td>
              </tr>
            )
          ) : (
            <tr>
              <td>Loading...</td>
            </tr>
          )}
        </tbody>
      </Table>
      {showDeleteModal && (
        <ConfirmationBox
          handleDelete={deleteHospital}
          toggleModal={toggleDeleteModal}
          showModal={showDeleteModal}
        >
          Are you sure?
        </ConfirmationBox>
      )}
    </>
  );
};

export default HospitalTable;
