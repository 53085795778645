import Noty from 'noty';

import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';

function alert(type: string, text: string, options?: any) {
  new Noty({
    theme: 'mint',
    layout: 'topRight',
    timeout: 3000,
    type,
    text,
    progressBar: false,
    ...options
  }).show();
}

export function error(text: string, options?: any) {
  alert('error', text, {
    ...options
  });
}

export function warn(text: string, options?: any) {
  alert('warning', text, {
    ...options
  });
}

export function success(text: string, options?: any) {
  alert('success', text, {
    ...options
  });
}

export function info(text: string, options?: any) {
  alert('info', text, {
    ...options
  });
}
