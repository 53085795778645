import React from 'react';
import { INews } from 'src/services/api/news';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { letterLimiter } from 'src/utils/stringManipuation';
import { getLiteralDate } from 'src/utils/date';

interface INewsTableRowProps {
  index: number;
  news: INews;
  toggleDeleteModal: (id?: string) => void;
}

const NewsTableRow: React.FC<INewsTableRowProps> = props => {
  const { index, news, toggleDeleteModal } = props;

  return (
    <tr>
      <td>{index + 1}</td>
      <td>{news.title && letterLimiter(news.title, 20)}</td>
      <td>
        <a href={news.url} target={'_blank'}>
          {news.url && letterLimiter(news.url, 20)}
        </a>
      </td>
      <td>{news.description && letterLimiter(news.description, 25)}</td>
      <td>{news.source && letterLimiter(news.source, 20)}</td>
      <td>
        <a href={news.imageUrl} target={'_blank'}>
          {news.imageUrl && letterLimiter(news.imageUrl, 20)}
        </a>
      </td>

      <td>{getLiteralDate(news.uploadedAt)}</td>
      <td>
        <Link to={`/news/edit/${news._id}`}>
          <Button>Edit</Button>
        </Link>

        <Button
          className={'ml-1'}
          variant="danger"
          onClick={() => toggleDeleteModal(news._id)}
        >
          Delete
        </Button>
      </td>
    </tr>
  );
};

export default NewsTableRow;
