import * as yup from 'yup';

export const globalDataSchema = yup.object().shape({
  confirmedTotal: yup
    .number()
    .min(0, 'Cant be a negative number')
    .required('Cant be empty'),
  recoveredTotal: yup
    .number()
    .min(0, 'Cant be a negative number')
    .required('Cant be empty'),
  deathTotal: yup
    .number()
    .min(0, 'Cant be a negative number')
    .required('Cant be empty')
});

export const nepalDataSchema = yup.object().shape({
  testedTotal: yup
    .number()
    .min(0, 'Cant be a negative number')
    .required('Cant be empty'),
  confirmedTotal: yup
    .number()
    .min(0, 'Cant be a negative number')
    .required('Cant be empty'),
  recoveredTotal: yup
    .number()
    .min(0, 'Cant be a negative number')
    .required('Cant be empty'),
  deathTotal: yup
    .number()
    .min(0, 'Cant be a negative number')
    .required('Cant be empty')
});
