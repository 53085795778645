import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface IConfirmationBox {
  showModal: boolean;
  confirmText?: string;
  handleDelete: () => void;
  toggleModal: () => void;
}
const ConfirmationBox: React.FC<IConfirmationBox> = props => {
  return (
    <Modal show={props.showModal} onHide={props.toggleModal}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer className="border-0">
        <Button variant="link" className="mr-2" onClick={props.toggleModal}>
          Cancel
        </Button>
        <Button variant="primary" className="px-3" onClick={props.handleDelete}>
          {props.confirmText ? props.confirmText : 'Delete'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmationBox;
