import React from 'react';
import { Switch, Route } from 'react-router-dom';

import * as routes from 'src/constants/routes';
import AddRequester from './Add';
import EditRequester from './Edit';
import ListRequester from './List';

const Router: React.FC = () => (
  <Switch>
    <Route exact path={routes.FRONTLINE} component={ListRequester} />
    <Route exact path={routes.FRONTLINE_REQUESTER} component={ListRequester} />
    <Route path={routes.FRONTLINE_REQUESTER_ADD} component={AddRequester} />
    <Route path={routes.FRONTLINE_REQUESTER_EDIT} component={EditRequester} />
  </Switch>
);

export default Router;
