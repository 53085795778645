import React, { FC, useEffect, useState } from 'react';
import { InjectedFormikProps } from 'formik';
import { useParams } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

import { ISupporterFormElementsValues } from '../common/SupporterFormElement';
import { fetchSupporterByIdAPI, ISupporter } from 'src/services/api/frontLine';
import EditSupporterForm from './form';

interface IEditSupporterProps extends RouteComponentProps {}

const EditSupporter: FC<InjectedFormikProps<
  IEditSupporterProps,
  ISupporterFormElementsValues
>> = props => {
  const { id = '' } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [formData, setFormData] = useState<ISupporter>({} as ISupporter);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchSupporterByIdAPI(id);
        setFormData(response);
        setIsLoaded(true);
      } catch (e) {
        throw e;
      }
    })();
  }, []);

  return (
    <div className="container">
      <h4 className="text-center" style={{ margin: '20px' }}>
        Update Supporter
      </h4>
      {isLoaded && formData ? (
        <EditSupporterForm {...props} supporter={formData} />
      ) : (
        <p className="text-center">Loading...</p>
      )}
    </div>
  );
};

export default EditSupporter;
