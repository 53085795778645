import { AxiosResponse } from 'axios';

import axios from 'src/utils/axios';
import { IPagination } from 'src/components/Pagination/Pagination';

export interface IContact {
  email: string;
  phone: string;
}
interface IFrontline {
  name: string;
  contact: IContact;
  organization: string;
  location: string;
  isVerified: boolean;
  others: string;
}
export interface IRequester extends IFrontline {
  _id: string;
  isFulfilled: boolean;
  fulfilledBy: Array<ISupporter>;
  requestedItems: string[];
}

interface ICreateRequestPayload extends IFrontline {
  isFulfilled: boolean;
  fulfilledBy: Array<string>;
  requestedItems: Array<string>;
}

export async function createRequestAPI(payload: ICreateRequestPayload) {
  try {
    const response: AxiosResponse = await axios.post(
      `/frontline/requests`,
      payload
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

interface IUpdateRequestAPIPayload extends ICreateRequestPayload {
  id: string;
}

export async function updateRequestAPI(payload: IUpdateRequestAPIPayload) {
  try {
    const { id, ...rest } = payload;
    const response: AxiosResponse = await axios.patch(
      `/frontline/requests/${id}`,
      rest
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}
interface IRequesterResponse extends IPagination {
  docs: Array<IRequester>;
}
export interface IFetchRequesterAPIPayload {
  page: number;
  size: number;
  name: string;
  items: string;
}

export async function fetchRequesterAPI(payload: IFetchRequesterAPIPayload) {
  try {
    const response: AxiosResponse<IRequesterResponse> = await axios.get(
      `frontline/requests`,
      { params: payload }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteRequesterAPI(id: string) {
  try {
    if (id) {
      const response: AxiosResponse = await axios.delete(
        `frontline/requests/${id}`
      );

      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function fetchRequestereByIdAPI(id: string) {
  try {
    const response: AxiosResponse<IRequester> = await axios.get(
      `frontline/requests/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
export interface ISupporterDropdownList {
  _id: string;
  name: string;
  organization: string;
}
export async function fetchSuppotersDropDownList() {
  try {
    const response: AxiosResponse<ISupporterDropdownList[]> = await axios.get(
      `frontline/supporters/dropdown`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export interface ISupporter extends IFrontline {
  _id: string;
  providedItems: string[];
  fulfillByDate: string;
}

interface ICreateSupporterPayload extends IFrontline {
  providedItems: string[];
  fulfillByDate: string;
}

export async function createSupporter(payload: ICreateSupporterPayload) {
  try {
    const response: AxiosResponse = await axios.post(
      `/frontline/supporters`,
      payload
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

interface ISupporterResponse extends IPagination {
  docs: Array<ISupporter>;
}
export interface IFetchSupporterAPIPayload {
  page: number;
  size: number;
  name: string;
  items: string;
}

export async function fetchSupporterAPI(payload: IFetchSupporterAPIPayload) {
  try {
    const response: AxiosResponse<ISupporterResponse> = await axios.get(
      `frontline/supporters`,
      { params: payload }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteSupporter(id: string) {
  try {
    if (id) {
      const response: AxiosResponse = await axios.delete(
        `/frontline/supporters/${id}`
      );

      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function fetchSupporterByIdAPI(id: string) {
  try {
    const response: AxiosResponse<ISupporter> = await axios.get(
      `frontline/supporters/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

interface IUpdateSupporterAPIPayload extends ICreateSupporterPayload {
  _id: string;
}

export async function updateSupporterAPI(payload: IUpdateSupporterAPIPayload) {
  try {
    const { _id, ...rest } = payload;
    if (_id) {
      const response: AxiosResponse = await axios.patch(
        `/frontline/supporters/${_id}`,
        rest
      );
      return response.data;
    }
  } catch (error) {
    throw error;
  }
}
