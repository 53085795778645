import React from 'react';
import { InjectedFormikProps } from 'formik';
import { IOptions, NewsTypeOptions } from 'src/constants/options';
import { ValueType, ActionMeta } from 'react-select';
import { Form, Row, Col } from 'react-bootstrap';
import CustomSelectInput from 'src/components/Forms/CustomSelectInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getLiteralDate } from 'src/utils/date';

export interface INewsFormValues {
  title: string;
  description: string;
  source: string;
  url: string;
  imageUrl: string;
  uploadedAt: string;
  type: IOptions;
}

const NewsFormElements: React.FC<InjectedFormikProps<
  {},
  INewsFormValues
>> = props => {
  const { values, touched, errors, handleChange, setFieldValue } = props;

  const handleNewsTypeChange = (
    value: ValueType<IOptions>,
    action?: ActionMeta
  ) => {
    if (action && action.name) {
      setFieldValue(action.name, value);
    }
  };

  const handleUploadedDateChange = (date: any) => {
    setFieldValue('uploadedAt', date);
    console.log(date);
  };

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          News Type
        </Form.Label>
        <Col sm="10">
          <CustomSelectInput
            name={'type'}
            placeholder={'News Type'}
            selectedValue={values.type}
            handleChange={handleNewsTypeChange}
            options={NewsTypeOptions}
            isClearable={true}
          />
          {errors.type && touched.type && (
            <small className={'text-danger'}>{errors.type}</small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Title
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'title'}
            type={'text'}
            value={values.title}
            placeholder={'News Title'}
            onChange={handleChange}
          />
          {errors.title && touched.title && (
            <small className={'text-danger'}>{errors.title}</small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Description
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'description'}
            as={'textarea'}
            value={values.description}
            placeholder={'Description'}
            onChange={handleChange}
          />
          {errors.description && touched.description && (
            <small className={'text-danger'}>{errors.description}</small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Source
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'source'}
            type={'text'}
            value={values.source}
            placeholder={'Source'}
            onChange={handleChange}
          />
          {errors.source && touched.source && (
            <small className={'text-danger'}>{errors.source}</small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          News URL
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'url'}
            type={'text'}
            value={values.url}
            placeholder={'Full URL of news'}
            onChange={handleChange}
          />
          {errors.url && touched.url && (
            <small className={'text-danger'}>{errors.url}</small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Image URL
        </Form.Label>
        <Col sm="10">
          <Form.Control
            name={'imageUrl'}
            type={'text'}
            value={values.imageUrl}
            placeholder={'URL of image from news article'}
            onChange={handleChange}
          />
          {errors.imageUrl && touched.imageUrl && (
            <small className={'text-danger'}>{errors.imageUrl}</small>
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="2">
          Upload Date
        </Form.Label>
        <Col sm="10">
          <DatePicker
            name={'uploadedAt'}
            onChange={date => handleUploadedDateChange(date)}
            autoComplete={'off'}
            value={getLiteralDate(values.uploadedAt)}
            strictParsing
            className={'form-control'}
            placeholderText={'Date of publication'}
            dateFormat={'yyyy/MM/dd'}
          />
          {errors.uploadedAt && touched.uploadedAt && (
            <small className={'text-danger'}>{errors.uploadedAt}</small>
          )}
        </Col>
      </Form.Group>
    </>
  );
};

export default NewsFormElements;
