import React, { FC, useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { ValueType } from 'react-select';

import { fetchSupporterAPI, ISupporter } from 'src/services/api/frontLine';
import Pagination from 'src/components/Pagination';
import { IPagination } from 'src/components/Pagination/Pagination';
import SupporterTable from './Table';
import { IOptions } from 'src/constants/options';
import SupportFilter from './Table/SupporterTableFilter';

const initialPaginationState = {
  page: 1,
  size: 10,
  pages: 0,
  total: 0
};
export interface ISupporterFilters {
  name: string;
  supportItems: IOptions;
}
const initialSupporterFiltersState = {
  supportItems: { label: 'All Items', value: '' },
  name: ''
};

const SupporterList: FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [supporterList, setsupporterList] = useState<ISupporter[]>([]);
  const [pagination, setPagination] = useState<IPagination>(
    initialPaginationState
  );
  const [filters, setFilters] = useState<ISupporterFilters>(
    initialSupporterFiltersState
  );
  useEffect(() => {
    const { page, size } = pagination;
    getSupporterList(filters, page, size);
  }, [filters, pagination.page]);

  const fetchSuppporterList = async (
    filters: ISupporterFilters,
    page: number,
    size: number
  ) => {
    const { name, supportItems } = filters;
    try {
      const payload = {
        page,
        size,
        name,
        items: supportItems ? (supportItems.value as string) : ''
      };

      setIsLoaded(false);
      const response = await fetchSupporterAPI(payload);
      const { docs, ...rest } = response;
      setsupporterList(docs);
      setPagination(rest);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoaded(true);
    }
  };

  const getSupporterList = useRef(debounce(fetchSuppporterList, 400)).current;

  const handlePageChange = (pageNumber: number) => {
    setPagination(prevPaginationState => ({
      ...prevPaginationState,
      page: pageNumber
    }));
  };
  const handleSupportItemsChange = (value: ValueType<IOptions>) => {
    const selectedField = value as IOptions;
    setFilters({ ...filters, supportItems: selectedField });
  };
  const handleSearchKeywordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilters({
      name: event.currentTarget.value,
      supportItems: { label: 'All Items', value: '' }
    });
  };
  return (
    <>
      <h3> Supporter List</h3>
      <SupportFilter
        filter={filters}
        handleSearchKeywordChange={handleSearchKeywordChange}
        handleSupportItemsChange={handleSupportItemsChange}
      />
      <SupporterTable
        isLoaded={isLoaded}
        page={pagination.page}
        size={pagination.size}
        supporterList={supporterList}
        fetchSuppporterList={fetchSuppporterList}
        filter={filters}
      />
      <Pagination {...pagination} handlePageChange={handlePageChange} />
    </>
  );
};
export default SupporterList;
