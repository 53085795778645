import * as React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as routes from 'src/constants/routes';

const NavbarComponent = () => (
  <Navbar bg="light" expand="lg">
    <div className="container-fluid">
      <Link className="navbar-brand" to={routes.DASHBOARD}>
        COVID Nepal Admin
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto"></Nav>
        <Link className="nav-link" to={routes.COVID_CASES}>
          Live Count
        </Link>
        <Link className="nav-link" to={routes.HOSPITAL}>
          Hospital
        </Link>
        <NavDropdown title="Support" id="basic-nav-dropdown">
          <Link className="dropdown-item" to={routes.FRONTLINE_REQUESTER}>
            Requester
          </Link>
          <Link className="dropdown-item" to={routes.FRONTLINE_SUPPORTER}>
            Supporter
          </Link>
        </NavDropdown>
        <Link className="nav-link" to={routes.NEWS}>
          News
        </Link>
      </Navbar.Collapse>
    </div>
  </Navbar>
);
export default NavbarComponent;
