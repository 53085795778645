import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { INews, deleteNewsAPI } from 'src/services/api/news';
import ConfirmationBox from 'src/components/ConfrimationBox';
import * as noty from 'src/utils/noty';
import { IMeta } from 'src/interface/common';
import NewsTableRow from './NewsTableRow';

interface INewsTableProps {
  newsList: Array<INews>;
  isLoaded: boolean;
  meta: IMeta;
  refresh: () => void;
}

const NewsTable: React.FC<INewsTableProps> = props => {
  const [showDeleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');
  const { newsList, isLoaded, meta, refresh } = props;

  const toggleDeleteModal = (id?: string) => {
    if (id) {
      setDeleteId(id);
    }
    setDeleteModal(!showDeleteModal);
  };

  const deleteNews = async () => {
    try {
      await deleteNewsAPI(deleteId);
      noty.success('News deleted successfully');
      toggleDeleteModal();
      refresh();
    } catch (e) {
      noty.error('Failed to delete news');
      console.log(e);
    }
  };

  return (
    <>
      <Table style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Title</th>
            <th>News URL</th>
            <th>Description</th>
            <th>Source</th>
            <th>Image URL</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoaded ? (
            newsList.length > 0 ? (
              newsList.map((news, index: number) => {
                const ind = meta.page * meta.size + index;
                return (
                  <NewsTableRow
                    key={news._id}
                    index={ind}
                    news={news}
                    toggleDeleteModal={toggleDeleteModal}
                  />
                );
              })
            ) : (
              <tr>
                <td>No records found</td>
              </tr>
            )
          ) : (
            <tr>
              <td>Loading...</td>
            </tr>
          )}
        </tbody>
      </Table>
      {showDeleteModal && (
        <ConfirmationBox
          handleDelete={deleteNews}
          toggleModal={toggleDeleteModal}
          showModal={showDeleteModal}
        >
          Are you sure?
        </ConfirmationBox>
      )}
    </>
  );
};

export default NewsTable;
