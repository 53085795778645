import { monthList } from 'src/constants/options';

// return date in "month day, year" format i.e. "March 22, 2020"
export const getLiteralDate = (date: string) => {
  if (date !== '') {
    const fullDate = new Date(date);
    const month = monthList[fullDate.getMonth()];
    const day = fullDate.getDate();
    const year = fullDate.getFullYear();
    return `${month} ${day}, ${year}`;
  } else {
    return '';
  }
};
