import * as yup from 'yup';

export const supporterSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required'),
  phone: yup.string().required('Phone number is required')
  // requestedItems: yup.array().required("Requested item is required"),
  // isVerified: yup
  //   .string()
  //   .nullable()
  //   .required("Verified is required"),
});
