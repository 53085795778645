import React, { FC, useEffect, useState } from 'react';
import axios, { Canceler } from 'axios';
import { Row, Col, Button } from 'react-bootstrap';
import { ValueType } from 'react-select';
import { Link } from 'react-router-dom';

import { ProvinceOptions, IOptions } from 'src/constants/options';
import CustomSelectInput from 'src/components/Forms/CustomSelectInput';
import { IDistrict } from 'src/constants/common';
import * as CommonAPI from 'src/services/api/common.api';
import { IHospitalCapacityFilters } from '../HospitalMain';
import * as routes from 'src/constants/routes';

interface IHospitalTableFilter {
  filter: IHospitalCapacityFilters;
  handleDistrictFilterChange: (value: ValueType<IOptions>) => void;
  handleProvinceFilterChange: (value: ValueType<IOptions>) => void;
  handleSearchKeywordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const HospitalTableFilter: FC<IHospitalTableFilter> = props => {
  const [districtListOption, setDistrictList] = useState<IOptions[]>([]);
  const [loadingDistrict, setLoadingDistrict] = useState<boolean>(true);
  const { handleProvinceFilterChange, handleDistrictFilterChange } = props;

  let cancel: Canceler | null;

  useEffect(() => {
    // update district
    setLoadingDistrict(true);
    if (cancel) {
      cancel();
    }

    const cancelToken = new axios.CancelToken(c => {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    });
    if (props.filter.province) {
      const province = props.filter.province.value as string;
      CommonAPI.getDistritByProvince(province, cancelToken).then(
        (districtList: IDistrict[]) => {
          const districtOptions = districtList.map(d => ({
            label: d.name.en,
            value: d.name.en,
            nepali: d.name.np
          }));
          districtOptions.unshift({
            label: 'All Districts',
            value: '',
            nepali: ''
          });
          cancel = null;
          setDistrictList(districtOptions);
          setLoadingDistrict(false);
        },
        e => {
          if (axios.isCancel(e)) {
            console.error('Request is canceled.');
          } else {
            setDistrictList([]);
            setLoadingDistrict(false);
          }
        }
      );
      return () => {
        if (cancel) {
          cancel();
        }
      };
    }
  }, [props.filter.province]);

  return (
    <Row className={'my-3'}>
      <Col sm={3}>
        <input
          placeholder="Search by Hospital Name"
          type="text"
          className="form-control form-control-sm cus-input"
          value={props.filter.hospitalName}
          onChange={event => props.handleSearchKeywordChange(event)}
        />
      </Col>
      <Col sm={4} />
      <Col sm={2}>
        <CustomSelectInput
          name="province"
          placeholder="Select Province"
          options={ProvinceOptions}
          selectedValue={props.filter.province}
          handleChange={handleProvinceFilterChange}
        />
      </Col>
      <Col sm={2}>
        <CustomSelectInput
          name="district"
          placeholder="Select District"
          options={districtListOption}
          handleChange={handleDistrictFilterChange}
          isLoading={loadingDistrict}
          selectedValue={props.filter.district}
        />
      </Col>

      <Col sm={1}>
        <div className={'text-right'}>
          <Link to={routes.HOSPITAL_ADD}>
            <Button className={'cus-button'}>Add Hospital</Button>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default HospitalTableFilter;
