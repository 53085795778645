import React from 'react';
import {
  INepalCount,
  IUpdateNepalCountPayload,
  updateNepalCountAPI
} from 'src/services/api/covidCases';
import { InjectedFormikProps, withFormik } from 'formik';
import CovidCasesFormElements, {
  ICovidCasesFormValues
} from '../../common/Form/CovidCasesFormElements';
import { Row, Col, Form, Button } from 'react-bootstrap';
import * as routes from 'src/constants/routes';
import * as noty from 'src/utils/noty';
import { Link } from 'react-router-dom';
import { nepalDataSchema } from 'src/validations/covidCases';

interface IUpdateNepalCasesFormProps {
  nepalCounts: INepalCount;
}

const UpdateNepalCasesForm: React.FC<InjectedFormikProps<
  IUpdateNepalCasesFormProps,
  ICovidCasesFormValues
>> = props => {
  const { handleSubmit, isSubmitting } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <CovidCasesFormElements {...props} />
      <Row>
        <Col sm="12" className="text-right mt-3">
          <Form.Group>
            <Link to={routes.COVID_CASES}>
              <Button variant="link" className="mr-4">
                Cancel
              </Button>
            </Link>
            <Button
              variant="primary"
              type="submit"
              className="px-4"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Updating...' : 'Update Nepal Count'}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

const WrappedWithFormik = withFormik<
  IUpdateNepalCasesFormProps,
  ICovidCasesFormValues
>({
  enableReinitialize: true,
  mapPropsToValues(props) {
    const { nepalCounts } = props;

    return {
      testedTotal: nepalCounts.testedTotal,
      confirmedTotal: nepalCounts.confirmedTotal,
      deathTotal: nepalCounts.deathTotal,
      recoveredTotal: nepalCounts.recoveredTotal
    };
  },
  validationSchema: nepalDataSchema,
  handleSubmit: async (values, { props, setSubmitting }) => {
    const payload: IUpdateNepalCountPayload = {
      id: props.nepalCounts._id,
      confirmedTotal: values.confirmedTotal,
      deathTotal: values.deathTotal,
      recoveredTotal: values.recoveredTotal,
      testedTotal: values.testedTotal ? values.testedTotal : -1
    };

    try {
      setSubmitting(true);
      await updateNepalCountAPI(payload);
      noty.success('Nepal Cases updated successfully');
      window.location.assign(routes.COVID_CASES);
    } catch (error) {
      noty.error('Failed to update Nepal Cases');
    } finally {
      setSubmitting(false);
    }
  }
});

export default WrappedWithFormik(UpdateNepalCasesForm);
