export const DASHBOARD = '/';

export const VIRUS_COUNT_VIEW = '/:virusId/detail';
export const VIRUS_COUNT_EDIT = '/:virusId/edit';
export const VIRUS_COUNT_ADD = '/add';

export const HOSPITAL = '/hospital';
export const HOSPITAL_ADD = '/hospital/add';
export const HOSPITAL_EDIT = '/hospital/edit/:id';

export const COVID_CASES = '/covidCases';
export const COVID_CASES_UPDATE_GLOBAL = '/covidCases/global/update';
export const COVID_CASES_UPDATE_NEPAL = '/covidCases/nepal/update';

export const FRONTLINE = '/frontline';
export const FRONTLINE_REQUESTER = '/frontline/requester';
export const FRONTLINE_REQUESTER_ADD = '/frontline/requester/add';
export const FRONTLINE_REQUESTER_EDIT = '/frontline/requester/edit/:id';
export const FRONTLINE_SUPPORTER = '/frontline/supporter';
export const FRONTLINE_SUPPORTER_ADD = '/frontline/supporter/add';
export const FRONTLINE_SUPPORTER_EDIT = '/frontline/supporter/edit/:id';

export const NEWS = '/news';
export const NEWS_ADD = '/news/add';
export const NEWS_EDIT = '/news/edit/:id';
