import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { ValueType } from 'react-select';
import { Link } from 'react-router-dom';

import { SupportItemsList, IOptions } from 'src/constants/options';
import CustomSelectInput from 'src/components/Forms/CustomSelectInput';
import * as routes from 'src/constants/routes';
import { ISupporterFilters } from '../SupporterList';

interface ISupporterTableFilter {
  filter: ISupporterFilters;
  handleSupportItemsChange: (value: ValueType<IOptions>) => void;
  handleSearchKeywordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SupporterTableFilter: FC<ISupporterTableFilter> = props => {
  const [itemList, setItemList] = useState<IOptions[]>(SupportItemsList);
  useEffect(() => {
    const supportList = itemList.map(item => ({
      label: item.label,
      value: item.label
    }));
    supportList.unshift({ label: 'All Items', value: '' });
    setItemList(supportList);
  }, []);

  return (
    <Row className={'my-3'}>
      <Col sm={3}>
        <input
          placeholder="Search by Name"
          type="text"
          className="form-control form-control-sm cus-input"
          value={props.filter.name}
          onChange={event => props.handleSearchKeywordChange(event)}
        />
      </Col>
      <Col sm={6} />
      <Col sm={2}>
        <CustomSelectInput
          name="Support Items"
          placeholder="Select Support item"
          options={itemList}
          selectedValue={props.filter.supportItems}
          handleChange={props.handleSupportItemsChange}
        />
      </Col>

      <Col sm={0.5}>
        <div className={'text-right'}>
          <Link to={routes.FRONTLINE_SUPPORTER_ADD}>
            <Button className={'cus-button'}>Add Supporter</Button>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default SupporterTableFilter;
