import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Row, Col, Button } from 'react-bootstrap';
import NepalCases from './NepalCases';
import GlobalCases from './GlobalCases';
import {
  IGlobalCount,
  INepalCount,
  fetchGlobalLiveCountAPI,
  fetchNepalLiveCountAPI
} from 'src/services/api/covidCases';

const CovidCasesMain: React.FC = () => {
  const [globalLiveData, setGlobalLiveData] = useState<IGlobalCount>(
    {} as IGlobalCount
  );
  const [nepalLiveData, setNepalLiveData] = useState<INepalCount>(
    {} as INepalCount
  );
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchGlobalLiveData = async () => {
    try {
      const response = await fetchGlobalLiveCountAPI();
      setGlobalLiveData(response);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoaded(true);
    }
  };

  const fetchNepalLiveData = async () => {
    try {
      const response = await fetchNepalLiveCountAPI();
      setNepalLiveData(response);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoaded(true);
    }
  };

  const fetchAllData = () => {
    setIsLoaded(false);
    fetchGlobalLiveData();
    fetchNepalLiveData();
  };

  return (
    <div className="container-fluid" style={{ marginTop: '20px' }}>
      <Row>
        <Col>
          <h3>Live Cases Count</h3>
        </Col>
        <Col>
          <div className={'text-right'}>
            <Button onClick={() => fetchAllData()} disabled={!isLoaded}>
              {isLoaded ? 'Refresh' : 'Refreshing...'}
            </Button>
          </div>
        </Col>
      </Row>

      <Tabs
        defaultActiveKey="nepal"
        id="news-main-tabs"
        style={{ marginTop: '10px' }}
      >
        <Tab eventKey="nepal" title="Nepal Data">
          <NepalCases liveData={nepalLiveData} />
        </Tab>
        <Tab eventKey="global" title="Global Data">
          <GlobalCases liveData={globalLiveData} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default CovidCasesMain;
