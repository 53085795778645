import React from 'react';
import { Switch, Route } from 'react-router-dom';

import * as routes from 'src/constants/routes';
import NewsMain from './Main';
import AddNews from './Add';
import EditNews from './Edit';

const NewsRouter: React.FC = () => (
  <Switch>
    <Route exact path={routes.NEWS} component={NewsMain} />
    <Route exact path={routes.NEWS_ADD} component={AddNews} />
    <Route exact path={routes.NEWS_EDIT} component={EditNews} />
  </Switch>
);
export default NewsRouter;
