import React from 'react';
import { withFormik, InjectedFormikProps } from 'formik';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import HospitalFormElements, {
  IHospitalFormElementsValues
} from '../common/hospitalFormElements';
import { IHospital, ILanguage } from 'src/services/api/hospital';
import { hospitalSchema } from 'src/validations/hospital';
import { updateHospitalCapacity } from 'src/services/api/hospital';
import * as noty from 'src/utils/noty';
import * as routes from 'src/constants/routes';

interface IEditHospitalForm {
  hospital: IHospital;
}
const EditHospitalForm: React.FC<InjectedFormikProps<
  IEditHospitalForm,
  IHospitalFormElementsValues
>> = props => {
  const { handleSubmit } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <HospitalFormElements {...props} />
      <Row>
        <Col sm="12" className="text-right mt-3">
          <Form.Group controlId="terminalAction">
            <Link to={routes.HOSPITAL}>
              <Button variant="link" className="mr-4">
                Cancel
              </Button>
            </Link>

            <Button variant="primary" type="submit" className="px-4">
              Update Hospital
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};
const WrappedWithFormik = withFormik<
  IEditHospitalForm,
  IHospitalFormElementsValues
>({
  enableReinitialize: true,
  mapPropsToValues(props) {
    const { hospital } = props;

    return {
      name_en: hospital.name.en || '',
      name_np: hospital.name.np || '',
      nameSlug: hospital.nameSlug,
      hospitalType: {
        label: hospital.hospitalType ? hospital.hospitalType.en : '',
        value: hospital.hospitalType ? hospital.hospitalType.en : '',
        nepali: hospital.hospitalType ? hospital.hospitalType.np : ''
      },
      hospitalTag: hospital.tags
        ? hospital.tags.map(tag => ({
            label: tag,
            value: tag
          }))
        : [],
      openDays_en: hospital.openDays ? hospital.openDays.en : '',
      openDays_np:
        hospital.openDays && hospital.openDays.np ? hospital.openDays.np : '',
      location_en: hospital.location ? hospital.location.en : '',
      location_np:
        hospital.location && hospital.location.np ? hospital.location.np : '',
      mapLink: hospital.mapLink || '',
      totalBeds: hospital.totalBeds ? hospital.totalBeds.toString() : '',
      icu: hospital.icu ? hospital.icu.toString() : '',
      ventilators: hospital.ventilators ? hospital.ventilators.toString() : '',
      numIsolationBeds: hospital.numIsolationBeds
        ? hospital.numIsolationBeds.toString()
        : '',
      availableBeds: hospital.availableBeds
        ? hospital.availableBeds.toString()
        : '',
      govtDesignated: {
        label: hospital.govtDesignated ? 'Yes' : 'No',
        value: hospital.govtDesignated || false
      },
      province: {
        label: hospital.province.name ? hospital.province.name.en : '',
        value: hospital.province.code,
        nepali:
          hospital.province.name && hospital.province.name.np
            ? hospital.province.name.np
            : ''
      },
      district: {
        label: hospital.district ? hospital.district.en : '',
        value: hospital.district ? hospital.district.en : '',
        nepali: hospital.district.np || ''
      },
      contact_en: hospital.contact
        ? hospital.contact
            .map((value: ILanguage, index) => `${value.en} `)
            .join()
        : '',
      contact_np: hospital.contact
        ? hospital.contact
            .map((value: ILanguage, index) => {
              if (value.np) {
                return `${value.np}`;
              }
            })
            .join()
        : '',
      availableTime_en: hospital.availableTime
        ? hospital.availableTime
            .map((value: ILanguage, index) => `${value.en}`)
            .join()
        : '',
      availableTime_np: hospital.availableTime
        ? hospital.availableTime
            .map((value: ILanguage, index) => {
              if (value.np) {
                return `${value.np} `;
              }
            })
            .join()
        : '',
      focalPoint_en: hospital.focalPoint ? hospital.focalPoint.en : '',
      focalPoint_np:
        hospital.focalPoint && hospital.focalPoint.np
          ? hospital.focalPoint.np
          : '',
      testingProcess_en:
        hospital.testingProcess && hospital.testingProcess.en
          ? hospital.testingProcess.en
          : '',
      testingProcess_np:
        hospital.testingProcess && hospital.testingProcess.np
          ? hospital.testingProcess.np
          : '',
      covidTest: {
        label: hospital.covidTest ? 'Yes' : 'No',
        value: hospital.covidTest || false
      },
      isVerified: {
        label: hospital.isVerified ? 'Yes' : 'No',
        value: hospital.isVerified || false
      },
      authorizedCovidTest: {
        label: hospital.authorizedCovidTest ? 'Yes' : 'No',
        value: hospital.authorizedCovidTest || false
      },
      priority: hospital.priority ? hospital.priority.toString() : ''
    };
  },
  validationSchema: hospitalSchema,
  handleSubmit: async (values, { props, setSubmitting }) => {
    const province = {
      code: values.province ? (values.province.value as string) : '',
      name: {
        np: values.province ? values.province.nepali : '',
        en: values.province ? values.province.label : ''
      }
    };
    const availableTimeEn = values.availableTime_en.split(',');
    const availableTimeNp = values.availableTime_np.split(',');
    const availableTime = availableTimeEn.map(
      (value: string, index: number) => {
        return {
          en: value,
          np: availableTimeNp[index]
        };
      }
    );

    const contact_en = values.contact_en.split(',');
    const contact_np = values.contact_np.split(',');
    const contact = contact_en.map((value: string, index: number) => {
      return {
        en: value,
        np: contact_np[index] || ''
      };
    });
    // payload
    const payload: IHospital = {
      coordinates: [],
      name: {
        en: values.name_en,
        np: values.name_np
      },
      hospitalType: {
        en: values.hospitalType ? (values.hospitalType.value as string) : '',
        np: values.hospitalType ? values.hospitalType.nepali : ''
      },
      tags: values.hospitalTag
        ? values.hospitalTag.map(tag => tag.value as string)
        : [],

      availableTime,
      openDays: {
        en: values.openDays_en,
        np: values.openDays_np
      },
      location: {
        en: values.location_en,
        np: values.location_np
      },
      mapLink: values.mapLink,
      covidTest: values.covidTest ? (values.covidTest.value as boolean) : false,
      testingProcess: {
        en: values.testingProcess_en,
        np: values.testingProcess_np
      },
      govtDesignated: values.govtDesignated
        ? (values.govtDesignated.value as boolean)
        : false,
      numIsolationBeds: +values.numIsolationBeds,
      focalPoint: {
        en: values.focalPoint_en,
        np: values.focalPoint_np
      },
      contact,
      district: {
        en: values.district ? (values.district.value as string) : '',
        np: values.district ? (values.district.nepali as string) : ''
      },
      nameSlug: values.nameSlug,
      province,
      isVerified: values.isVerified
        ? (values.isVerified.value as boolean)
        : false,
      totalBeds: +values.totalBeds,
      availableBeds: +values.availableBeds,
      icu: +values.icu,
      ventilators: +values.ventilators,
      authorizedCovidTest: values.authorizedCovidTest
        ? (values.authorizedCovidTest.value as boolean)
        : false,
      priority: values.priority ? +values.priority : null
    };

    try {
      await updateHospitalCapacity(payload, props.hospital._id || '');
      noty.success('Hospital capacity updated successfully');
      window.location.assign(routes.HOSPITAL);
    } catch (e) {
      noty.error('Failed to update hositpal capacity');
      console.log(e);
    }
  }
});

export default WrappedWithFormik(EditHospitalForm);
