export interface IMeta {
  page: number;
  size: number;
  totalItems: number;
  totalPages: number;
}

export const initialMeta: IMeta = {
  page: 0,
  size: 10,
  totalItems: 1,
  totalPages: 1
};
