import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { InjectedFormikProps } from 'formik';
import { ValueType, ActionMeta } from 'react-select';

import CustomSelectInput from 'src/components/Forms/CustomSelectInput';
import { IOptions, YesNoOption, SupportItemsList } from 'src/constants/options';
import FormCheckbox from 'src/components/Forms/FormCheckbox';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getLiteralDate } from 'src/utils/date';

export interface ISupporterFormElementsValues {
  _id?: string;
  name: string;
  email: string;
  phone: string;
  organization: string;
  location: string;
  isVerified: IOptions | null;
  providedItems: string[];
  others: string;
  otherSupportItems: string;
  fulfillByDate: string;
}

const SupporterFormElement: React.FC<InjectedFormikProps<
  {},
  ISupporterFormElementsValues
>> = props => {
  const { values, touched, errors, handleChange, setFieldValue } = props;

  const handleCustomChange = (
    value: ValueType<IOptions>,
    action?: ActionMeta
  ) => {
    if (action && action.name) {
      setFieldValue(action.name, value);
    }
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const { providedItems } = values;
    setFieldValue(name, value);
    let newValue;
    if (providedItems.includes(value)) {
      newValue = providedItems.filter(item => item !== value);
    } else {
      newValue = [...providedItems, value];
    }
    setFieldValue('providedItems', newValue);
  };
  const handleUploadedDateChange = (date: any) => {
    setFieldValue('fulfillByDate', date);
    console.log(date);
  };
  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Your Name
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'name'}
            type="text"
            value={values.name}
            placeholder=""
            onChange={handleChange}
          />
          {errors['name'] && touched['name'] ? (
            <small className={'text-danger'}>{errors['name']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Your Contact (Email-add)
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'email'}
            type="email"
            placeholder=""
            value={values.email}
            onChange={handleChange}
          />
          {errors['email'] && touched['email'] ? (
            <small className={'text-danger'}>{errors['email']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="3">
          You Contact (Phone number) *
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'phone'}
            type="text"
            placeholder=""
            onChange={handleChange}
            value={values.phone}
          />
          {errors['phone'] && touched['phone'] ? (
            <small className={'text-danger'}>{errors['phone']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Where do you work? (Optional)
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'organization'}
            type="text"
            placeholder=""
            onChange={handleChange}
            value={values.organization}
          />
          {errors['organization'] && touched['organization'] ? (
            <small className={'text-danger'}>{errors['organization']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          What support are you looking for?
        </Form.Label>
        <Col sm="5">
          {SupportItemsList.map((SupportItem, index: number) => (
            <div className="form-check" key={index}>
              <FormCheckbox
                label={SupportItem.label as string}
                value={SupportItem.value as string}
                className={`form-check-input`}
                handleChange={handleCheckboxChange}
                checked={values.providedItems.includes(
                  SupportItem.value as string
                )}
              />
            </div>
          ))}
          <div className="form-check">
            <label className="form-check-label">Other:</label>
            <input
              className="ml-1"
              type="text"
              name={'otherSupportItems'}
              value={values.otherSupportItems}
              onChange={handleChange}
            />
          </div>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Any other support that you can provide which is not listed above?
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'others'}
            type="text"
            value={values.others}
            placeholder=""
            onChange={handleChange}
          />
          {errors['others'] && touched['others'] ? (
            <small className={'text-danger'}>{errors['others']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          By when can you provide the above support?
        </Form.Label>
        <Col sm="5">
          <DatePicker
            name={'fulfillByDate'}
            onChange={date => handleUploadedDateChange(date)}
            autoComplete={'off'}
            value={getLiteralDate(values.fulfillByDate)}
            strictParsing
            className={'form-control'}
            placeholderText={''}
            dateFormat={'yyyy/MM/dd'}
          />
          {errors['fulfillByDate'] && touched['fulfillByDate'] ? (
            <small className={'text-danger'}>{errors['fulfillByDate']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Which location you can provide support for? (Province, city, locality
          etc)
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'location'}
            type="text"
            value={values.location}
            placeholder=""
            onChange={handleChange}
          />
          {errors['location'] && touched['location'] ? (
            <small className={'text-danger'}>{errors['location']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Verified
        </Form.Label>
        <Col sm="5">
          <CustomSelectInput
            name={'isVerified'}
            placeholder={'Select'}
            selectedValue={values.isVerified}
            handleChange={handleCustomChange}
            options={YesNoOption}
          />
          {errors['isVerified'] && touched['isVerified'] ? (
            <small className={'text-danger'}>{errors['isVerified']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
    </>
  );
};
export default SupporterFormElement;
