import React from 'react';
import { Tabs, Tab, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as routes from 'src/constants/routes';
import GlobalNews from './GlobalNews';
import NepalNews from './NepalNews';
import Tips from './Tips';
import TopNews from './TopNews';

const NewsMain = () => {
  return (
    <div className="container-fluid" style={{ marginTop: '20px' }}>
      <Row>
        <Col>
          <h3>News Dashboard</h3>
        </Col>
        <Col>
          <div className={'text-right'}>
            <Link to={routes.NEWS_ADD}>
              <Button>Add News</Button>
            </Link>
          </div>
        </Col>
      </Row>

      <Tabs
        defaultActiveKey="global"
        id="news-main-tabs"
        style={{ marginTop: '10px' }}
      >
        <Tab eventKey="global" title="Global News">
          <GlobalNews />
        </Tab>
        <Tab eventKey="nepal" title="Nepal News">
          <NepalNews />
        </Tab>
        <Tab eventKey="tips" title="Tips">
          <Tips />
        </Tab>
        <Tab eventKey="top" title="Top News">
          <TopNews />
        </Tab>
      </Tabs>
    </div>
  );
};

export default NewsMain;
