import React, { useState } from 'react';
import { Table } from 'react-bootstrap';

import { ISupporter, deleteSupporter } from 'src/services/api/frontLine';
import SupporterTableRow from './SupporterTableRow';
import ConfirmationBox from 'src/components/ConfrimationBox';
import * as noty from 'src/utils/noty';
import { ISupporterFilters } from '../SupporterList';

interface ISupporterTableProps {
  page: number;
  size: number;
  supporterList: ISupporter[];
  isLoaded: boolean;
  fetchSuppporterList: (
    filters: ISupporterFilters,
    page: number,
    size: number
  ) => void;
  filter: ISupporterFilters;
}
const SupporterTable: React.FC<ISupporterTableProps> = props => {
  const [showDeleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');

  const toggleDeleteModal = (id?: string) => {
    if (id) {
      setDeleteId(id);
    }
    setDeleteModal(!showDeleteModal);
  };
  const supporterDelete = async () => {
    try {
      await deleteSupporter(deleteId);
      noty.success('Supporter deleted successfully');
      toggleDeleteModal();
      let newPage = props.page;
      if (props.supporterList.length === 1 && props.page > 1) {
        newPage -= 1;
      }
      props.fetchSuppporterList(props.filter, newPage, props.size);
    } catch (e) {
      noty.error('Failed to delete supporter');
      console.log(e);
    }
  };
  return (
    <>
      <Table style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Name </th>
            <th>Email</th>
            <th>Organization</th>
            <th>Contact No</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.isLoaded ? (
            props.supporterList.length > 0 ? (
              props.supporterList.map((supporter, index: number) => {
                const ind = (props.page - 1) * props.size + index;
                return (
                  <SupporterTableRow
                    key={supporter._id}
                    index={ind}
                    supporter={supporter}
                    toggleDeleteModal={toggleDeleteModal}
                  />
                );
              })
            ) : (
              <tr>
                <td>No records found</td>
              </tr>
            )
          ) : (
            <tr>
              <td>Loading...</td>
            </tr>
          )}
        </tbody>
      </Table>
      {showDeleteModal && (
        <ConfirmationBox
          handleDelete={supporterDelete}
          toggleModal={toggleDeleteModal}
          showModal={showDeleteModal}
        >
          Are you sure?
        </ConfirmationBox>
      )}
    </>
  );
};
export default SupporterTable;
