import React from 'react';
import { Switch, Route } from 'react-router-dom';

import * as routes from '../../../constants/routes';
import AddHospital from './Add';
import HospitalMain from './Main';
import EditHospital from './Edit';

const HospitalRouter: React.FC = () => (
  <Switch>
    <Route exact path={routes.HOSPITAL} component={HospitalMain} />
    <Route exact path={routes.HOSPITAL_ADD} component={AddHospital} />
    <Route exact path={routes.HOSPITAL_EDIT} component={EditHospital} />
  </Switch>
);
export default HospitalRouter;
