import React, { useState, useEffect } from 'react';
import { INews, fetchTipsAPI } from 'src/services/api/news';
import NewsTable from 'src/routes/dashboard/News/common/Table/NewsTable';

const Tips = () => {
  const [tips, setTips] = useState<INews[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    fetchTips();
  }, []);

  const fetchTips = async () => {
    try {
      const response = await fetchTipsAPI();
      setTips(response.docs);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoaded(true);
    }
  };

  return (
    <div className="container-fluid">
      <NewsTable
        newsList={tips}
        isLoaded={isLoaded}
        meta={{ page: 1, size: 0, totalItems: 0, totalPages: 10 }}
        refresh={fetchTips}
      />
    </div>
  );
};

export default Tips;
