import React from 'react';
import { Pagination as PG, Row, Col } from 'react-bootstrap';

export interface IPagination {
  total: number;
  size: number;
  page: number;
  pages: number;
}

interface IPaginationProps extends IPagination {
  range?: number;
  handlePageChange: (page: number) => void;
}

const Pagination: React.SFC<IPaginationProps> = ({
  page,
  pages: totalPages,
  total: totalItems,
  handlePageChange,
  range = 5,
  size
}) => {
  let items = [];
  let start = 1;
  let end = totalPages;

  if (end - start > range) {
    let lowerDiff = page - Math.floor(range / 2);
    let upperDiff = page + Math.floor(range / 2);

    if (lowerDiff > 0) {
      start = lowerDiff;

      let exceedRange = start + range - 1 - totalPages;

      if (exceedRange > 0 && start - exceedRange > 0) {
        start = start - exceedRange;
      }
    }

    if (upperDiff <= totalPages) {
      end = start + range - 1;
    }
  }

  for (let number = start; number <= end; number++) {
    items.push(
      <PG.Item
        key={number}
        active={number === page}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </PG.Item>
    );
  }

  let showingStart = 1;
  let showingEnd = size;

  if (page > 1) {
    showingStart += size;
    showingEnd += size;
  }

  if (totalItems < showingEnd) {
    showingEnd = totalItems;
  }
  return totalPages > 0 ? (
    <>
      <Row className="align-items-center pagination-wrapper sticky">
        <Col md="4">
          <small>
            Showing{' '}
            <span className="font-weight-bold">
              {size < totalItems ? size : totalItems}
            </span>{' '}
            of {totalItems} entries
          </small>
        </Col>

        <Col md="8">
          <PG className="small justify-content-end align-items-center">
            <li
              id="prev-button"
              className={`${page === 1 ? 'disabled' : ''}`}
              onClick={page === 1 ? () => {} : () => handlePageChange(page - 1)}
            >
              <a className="page-link">
                <span className="icon">
                  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M351,9a15,15 0 01 19,0l29,29a15,15 0 01 0,19l-199,199l199,199a15,15 0 01 0,19l-29,29a15,15 0 01-19,0l-236-235a16,16 0 01 0-24z" />
                  </svg>
                </span>
              </a>
            </li>

            {items}

            <li
              id="next-button"
              className={`${page === totalPages ? 'disabled' : ''}`}
              onClick={
                page === totalPages
                  ? () => {}
                  : () => handlePageChange(page + 1)
              }
            >
              <a className="page-link">
                <span className="icon">
                  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M312,256l-199-199a15,15 0 01 0-19l29-29a15,15 0 01 19,0l236,235a16,16 0 01 0,24l-236,235a15,15 0 01-19,0l-29-29a15,15 0 01 0-19z" />
                  </svg>
                </span>
              </a>
            </li>
          </PG>
        </Col>
      </Row>
    </>
  ) : null;
};

export default Pagination;
