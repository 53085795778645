import { AxiosResponse } from 'axios';

import axios from 'src/utils/axios';
import { IPagination } from 'src/components/Pagination/Pagination';

export interface ILanguage {
  en: string;
  np?: string;
}
export interface IProvince {
  code: string;
  name: ILanguage;
}
export interface IHospital {
  _id?: string;
  availableTime: ILanguage[];
  coordinates: [];
  contact: ILanguage[];
  name: ILanguage;
  hospitalType: ILanguage;
  tags: Array<string>;
  openDays: ILanguage;
  location: ILanguage;
  mapLink: string;
  totalBeds: number;
  availableBeds: number;
  covidTest: boolean;
  testingProcess: ILanguage;
  govtDesignated: boolean;
  numIsolationBeds: number;
  nameSlug: string;
  focalPoint: ILanguage;
  district: ILanguage;
  icu: number;
  ventilators: number;
  province: IProvince;
  isVerified: boolean;
  authorizedCovidTest: boolean;
  priority: number | null;
}

interface IHospitalCapacityResponse extends IPagination {
  docs: Array<IHospital>;
}

export interface IFetchHospitalCapacityAPIPayload {
  province?: string;
  district?: string;
  name?: string;
  page: number;
  size: number;
}

export async function fetchHospitalCapacityAPI(
  payload: IFetchHospitalCapacityAPIPayload
) {
  try {
    const response: AxiosResponse<IHospitalCapacityResponse> = await axios.get(
      `/hospitals`,
      { params: payload }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function createHospitalCapacity(payload: IHospital) {
  try {
    const response: AxiosResponse = await axios.post(`/hospitals`, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function fetchHospitalCapacityById(id: string) {
  try {
    const response: AxiosResponse<IHospital> = await axios.get(
      `/hospitals/id/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateHospitalCapacity(payload: IHospital, id: string) {
  try {
    if (id) {
      const response: AxiosResponse = await axios.put(
        `/hospitals/${id}`,
        payload
      );

      return response.data;
    }
  } catch (error) {
    throw error;
  }
}

export async function deleteHospitalCapacity(id: string) {
  try {
    if (id) {
      const response: AxiosResponse = await axios.delete(`/hospitals/${id}`);

      return response.data;
    }
  } catch (error) {
    throw error;
  }
}
