import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { InjectedFormikProps } from 'formik';
import { ValueType, ActionMeta } from 'react-select';

import CustomSelectInput from 'src/components/Forms/CustomSelectInput';
import { IOptions, YesNoOption, RequestItemsList } from 'src/constants/options';
import {
  fetchSuppotersDropDownList,
  ISupporterDropdownList
} from 'src/services/api/frontLine';
import SupportItemsCheckbox from 'src/components/Forms/FormCheckbox';

export interface IRequesterFormElementsValues {
  name: string;
  email: string;
  phone: string;
  organization: string;
  location: string;
  isVerified: IOptions | null;
  isFulfilled: IOptions | null;
  fulfilledBy: Array<IOptions> | null;
  requestedItems: string[];
  otherSupportItems: string;
  additionalSupport: string;
}

const RequesterFormElement: React.FC<InjectedFormikProps<
  {},
  IRequesterFormElementsValues
>> = props => {
  const { values, touched, errors, handleChange, setFieldValue } = props;

  const [supporterListOption, setsupporterListOption] = useState<IOptions[]>(
    []
  );
  const [loadingSupporter, setLoadingSupporter] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setLoadingSupporter(true);
      const supporterList = await fetchSuppotersDropDownList();
      const supporterOption = supporterList.map(
        (supporter: ISupporterDropdownList) => ({
          label: supporter.name,
          value: supporter._id
        })
      );
      setsupporterListOption(supporterOption);
      setLoadingSupporter(false);
    })();
  }, []);

  const handleCustomChange = (
    value: ValueType<IOptions | Array<IOptions>>,
    action?: ActionMeta
  ) => {
    if (action && action.name) {
      setFieldValue(action.name, value);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    const { requestedItems } = values;
    setFieldValue(name, value);
    let newValue;
    if (requestedItems.includes(value)) {
      newValue = requestedItems.filter(item => item !== value);
    } else {
      newValue = [...requestedItems, value];
    }
    setFieldValue('requestedItems', newValue);
  };

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Your Name
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'name'}
            type="text"
            value={values.name}
            placeholder={'Name'}
            onChange={handleChange}
          />
          {errors['name'] && touched['name'] ? (
            <small className={'text-danger'}>{errors['name']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Your Contact (Email-add)
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'email'}
            type="email"
            placeholder={'Email'}
            value={values.email}
            onChange={handleChange}
          />
          {errors['email'] && touched['email'] ? (
            <small className={'text-danger'}>{errors['email']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="3">
          You Contact (Phone number)
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'phone'}
            type="text"
            placeholder=""
            onChange={handleChange}
            value={values.phone}
          />
          {errors['phone'] && touched['phone'] ? (
            <small className={'text-danger'}>{errors['phone']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Where do you work?
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'organization'}
            type="text"
            placeholder=""
            onChange={handleChange}
            value={values.organization}
          />
          {errors['organization'] && touched['organization'] ? (
            <small className={'text-danger'}>{errors['organization']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Location? (State, City, Locality etc)
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'location'}
            type="text"
            value={values.location}
            placeholder=""
            onChange={handleChange}
          />
          {errors['location'] && touched['location'] ? (
            <small className={'text-danger'}>{errors['location']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          What support are you looking for?
        </Form.Label>
        <Col sm="5">
          {RequestItemsList.map((RequestItem, index: number) => (
            <div className="form-check" key={index}>
              <SupportItemsCheckbox
                label={RequestItem.label as string}
                value={RequestItem.value as string}
                className={`form-check-input`}
                checked={values.requestedItems.includes(
                  RequestItem.value as string
                )}
                handleChange={handleCheckboxChange}
              />
            </div>
          ))}

          <div className="form-check">
            <label className="form-check-label">Other:</label>
            <input
              className="ml-1"
              type="text"
              name={'otherSupportItems'}
              value={values.otherSupportItems}
              onChange={handleChange}
            />
          </div>
          {errors['requestedItems'] && touched['requestedItems'] ? (
            <small className={'text-danger'}>{errors['requestedItems']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Is Verified
        </Form.Label>
        <Col sm="5">
          <CustomSelectInput
            name={'isVerified'}
            placeholder={'Select'}
            selectedValue={values.isVerified}
            handleChange={handleCustomChange}
            options={YesNoOption}
          />
          {errors['isVerified'] && touched['isVerified'] ? (
            <small className={'text-danger'}>{errors['isVerified']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Is fulfilled
        </Form.Label>
        <Col sm="5">
          <CustomSelectInput
            name={'isFulfilled'}
            placeholder={'Select'}
            selectedValue={values.isFulfilled}
            handleChange={handleCustomChange}
            options={YesNoOption}
          />
          {errors['isFulfilled'] && touched['isFulfilled'] ? (
            <small className={'text-danger'}>{errors['isFulfilled']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          FulFilled by
        </Form.Label>
        <Col sm="5">
          <CustomSelectInput
            name={'fulfilledBy'}
            placeholder={'Select'}
            handleChange={handleCustomChange}
            options={supporterListOption}
            isClearable={true}
            selectedValue={values.fulfilledBy}
            isLoading={loadingSupporter}
            isMulti={true}
          />
          {errors['fulfilledBy'] && touched['fulfilledBy'] ? (
            <small className={'text-danger'}>{errors['fulfilledBy']}</small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Any other support needed that is not listed above?
        </Form.Label>
        <Col sm="5">
          <Form.Control
            name={'additionalSupport'}
            type="text"
            value={values.additionalSupport}
            placeholder={'Additional Support'}
            onChange={handleChange}
          />
          {errors['additionalSupport'] && touched['additionalSupport'] ? (
            <small className={'text-danger'}>
              {errors['additionalSupport']}
            </small>
          ) : (
            ''
          )}
        </Col>
      </Form.Group>
    </>
  );
};
export default RequesterFormElement;
