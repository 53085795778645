import React from 'react';
import {
  IGlobalCount,
  updateGlobalCountAPI,
  IUpdateGlobalCountPayload
} from 'src/services/api/covidCases';
import { InjectedFormikProps, withFormik } from 'formik';
import CovidCasesFormElements, {
  ICovidCasesFormValues
} from '../../common/Form/CovidCasesFormElements';
import { Row, Col, Form, Button } from 'react-bootstrap';
import * as routes from 'src/constants/routes';
import * as noty from 'src/utils/noty';
import { Link } from 'react-router-dom';
import { globalDataSchema } from 'src/validations/covidCases';

interface IUpdateGlobalCasesFormProps {
  globalCounts: IGlobalCount;
}

const UpdateGlobalCasesForm: React.FC<InjectedFormikProps<
  IUpdateGlobalCasesFormProps,
  ICovidCasesFormValues
>> = props => {
  const { handleSubmit, isSubmitting } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <CovidCasesFormElements {...props} />
      <Row>
        <Col sm="12" className="text-right mt-3">
          <Form.Group>
            <Link to={routes.COVID_CASES}>
              <Button variant="link" className="mr-4">
                Cancel
              </Button>
            </Link>
            <Button
              variant="primary"
              type="submit"
              className="px-4"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Updating...' : 'Update Global Count'}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

const WrappedWithFormik = withFormik<
  IUpdateGlobalCasesFormProps,
  ICovidCasesFormValues
>({
  enableReinitialize: true,
  mapPropsToValues(props) {
    const { globalCounts } = props;

    return {
      confirmedTotal: globalCounts.confirmedTotal,
      deathTotal: globalCounts.deathTotal,
      recoveredTotal: globalCounts.recoveredTotal
    };
  },
  validationSchema: globalDataSchema,
  handleSubmit: async (values, { props, setSubmitting }) => {
    const payload: IUpdateGlobalCountPayload = {
      id: props.globalCounts._id,
      confirmedTotal: values.confirmedTotal,
      deathTotal: values.deathTotal,
      recoveredTotal: values.recoveredTotal
    };

    try {
      setSubmitting(true);
      await updateGlobalCountAPI(payload);
      noty.success('Global Cases updated successfully');
      window.location.assign(routes.COVID_CASES);
    } catch (error) {
      noty.error('Failed to update Global Cases');
    } finally {
      setSubmitting(false);
    }
  }
});

export default WrappedWithFormik(UpdateGlobalCasesForm);
