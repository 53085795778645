import React, { FC } from 'react';
import Router from './Router';

const Hospital: FC = () => {
  return (
    <div className="container-fluid">
      <Router />
    </div>
  );
};

export default Hospital;
